<!--
 * @Author: 小洋人
 * @Date: 2024-08-05 09:39:24
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-09-25 10:44:19
 * @FilePath: \kangmisi-officialwebsite\src\views\components\details4.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div class="pages">
    <div class="title">应用场景</div>
    <card :cardList="cardList"></card>
    <div class="line"></div>
    <div class="title">行业痛点</div>
    <wayPoint :list="pointList"></wayPoint>
    <div class="line"></div>
    <div class="title">康米斯解决方案</div>
    <fang :list="fangList"></fang>
    <div class="line"></div>
    <div class="title">客户案例</div>
    <swiper :list="swiperList"></swiper>
    <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
  </div>
</template>
<script>
import card from "./components/card-num.vue";
import wayPoint from "./components/wayPoint.vue";
import light from "./components/light.vue";
import swiper from "./components/swiper.vue";
import fang from "./components/fang.vue";
export default {
  components: {
    wayPoint,
    card,
    light,
    swiper,
    fang
  },
  data() {
    return {
      swiperList: [
        {
          url: require("@/assets/images/solution/details4/s1.png"),
          title: "晋江环卫",
          html: `<span style='color: #0F3391;font-weight: Bold;'>5000+</span>环卫工人定位管理，考勤管理、排班调度`,
        },
      ],
      fangList:[
        {
          url: require("@/assets/images/solution/details4/fang1.png"),
          icon: require("@/assets/images/solution/details4/icon1.png"),
          title: "智能调度",
          text: "根据在班、在岗、在位的情况，智能调度派车，有效提高车辆利用率，提高运营效率，减少人工成本及时间浪费。车辆调度人员可根据司机/车辆实时状态调度，避免发生任务期冲突。",
        },
         {
          url: require("@/assets/images/solution/details4/fang2.png"),
          icon: require("@/assets/images/solution/details4/icon2.png"),
          title: "实时路径监管",
          text: "历史回放可查看选择时间内车辆的历史轨迹以及车辆位置、车辆速度、里程等信息。",
        },
           {
          url: require("@/assets/images/solution/details4/fang3.png"),
          icon: require("@/assets/images/solution/details4/icon3.png"),
          title: "视频监控",
          text: "通过硬件设备、云端后台、大数据算法三大核心技术，为车辆提供实时定位追踪、安全视频监控、行车轨迹回溯、多重告警通知、司机驾驶行为汇总等数据存档及图表分析。",
        },
         {
          url: require("@/assets/images/solution/details4/fang4.png"),
          icon: require("@/assets/images/solution/details4/icon4.png"),
          title: "考勤排班",
          text: "通过班次-考勤，对规定班次时间内对应的考勤人员进行考勤监督。",
        },
          {
          url: require("@/assets/images/solution/details4/fang5.png"),
          icon: require("@/assets/images/solution/details4/icon5.png"),
          title: "统计结算",
          text: "管理员做员工排班并统计考勤情况。通过轨迹查看分析出勤情况、是否按要求到达，计算考核。",
        },
         {
          url: require("@/assets/images/solution/details4/fang6.png"),
          icon: require("@/assets/images/solution/details4/icon6.png"),
          title: "车务管理",
          text: "信息管理、保养提醒、年检提醒、保险提醒。全方位车务信息管理、周期性短信/APP自动提醒，其他提醒设置、维保管理、事故管理、费用管理。",
        },
      ],
      pointList: [
        {
          url: require("@/assets/images/solution/details4/ponit1.png"),
          title: "合理调度难",
          text: "车辆状态难监控，调度效率低。",
        },
        {
          url: require("@/assets/images/solution/details4/ponit2.png"),
          title: "司机监管难",
          text: "司机未按指定路线行驶，时长里程难统计，任务安全难管控。",
        },
        {
          url: require("@/assets/images/solution/details4/ponit3.png"),
          title: "数据统计难",
          text: "作业趟数难统计，订单里程、费用同步不及时。",
        },
        {
          url: require("@/assets/images/solution/details4/ponit4.png"),
          title: "车务管理难",
          text: "车务档案管理繁杂，年检保险保养到期无提醒，维保、事故管理难。",
        },
         {
          url: require("@/assets/images/solution/details4/ponit5.png"),
          title: "费用管理难",
          text: "虚报难核实，报销对账难，油费、ETC等费用居高不下，台账不清晰，统计分析难。",
        },
        
      ],
      cardList: [
        {
          width: "304px",
        height:'207px',

          url: require("@/assets/images/solution/details4/card1.png"),
          text: "环卫",
        },
        {
          width: "304px",
        height:'207px',

          url: require("@/assets/images/solution/details4/card2.png"),
          text: "路桥养护",
        },
        {
          width: "304px",
        height:'207px',

          url: require("@/assets/images/solution/details4/card3.png"),
          text: "保险勘察",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2c2c2c;
  letter-spacing: 1px;
  margin: 40px 0;
}
.line {
  margin-top: 40px;
  width: 960px;
  height: 0px;
  border: 1px solid #dbdbdb;
}
.text {
  width: 960px;
  height: 60px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 24px;
}
.img2 {
  margin: 112px 0;
  width: 960px;
  height: 202px;
}
</style>