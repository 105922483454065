<template>
  <div class="pages">
    <div class="title">应用场景</div>
    <card-num :cardList="cardList"></card-num>
    <div class="line"></div>
    <div class="title">行业痛点</div>
    <wayPoint :list="pointList"></wayPoint>
    <div class="line"></div>
    <div class="title" style="margin-bottom: 24px">康米斯解决方案</div>
    <resolution :resolution-list="resolutionList"></resolution>
    <div class="line"></div>
    <div class="title">方案亮点</div>
    <!-- <div class="line"></div> -->
    <light2 :list="lightList" textWidth="288px"></light2>
    <div class="title">客户案例</div>
    <swiper :list="swiperList"></swiper>
    <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
  </div>
</template>
<script>
import cardNum from "./components/card-num.vue";

import wayPoint from "./components/wayPoint.vue";
import light2 from "./components/light2.vue";
import resolution from "./components/resolution.vue";
import swiper from "./components/swiper.vue";
export default {
  components: {
    wayPoint,
    cardNum,
    light2,
    resolution,
    swiper,
  },
  data() {
    return {
      lightList: [
        {
          url: require("@/assets/images/solution/details8/light1.png"),
          px: "96px",
          title: "提高管理效率",
          type: "html",
          text: "用车流程化，规范管理用车；<br>智能调度，降低空驶率，提高车辆使用率；<br>行驶数据、派车订单统计，方便公司考核。",
        },
        {
          url: require("@/assets/images/solution/details8/light2.png"),
          title: "提高驾驶安全性",
          px: "96px",
          type: "html",
          text: "自动统计里程油耗，实时故障告警，车辆成本有据可依：<br>监管行驶过程，异常用车提醒，杜绝公车私用。",
        },
        {
          url: require("@/assets/images/solution/details8/light3.png"),
          title: "降低运营成本",
          px: "96px",
          type: "html",
          text: "车辆维修保养、保险及时安排；<br>监测车辆行驶过程中的不规范驾驶行为，降低安全隐患。",
        },
      ],
      pointList: [
        {
          url: require("@/assets/images/solution/details8/point1.png"),
          title: "公车私用",
          type: "html",
          text: `无论是政府部门还是企事业单<br>位用车，公车私用已成诟病，<br>缺少必要的监管`,
        },
        {
          url: require("@/assets/images/solution/details8/point2.png"),
          title: "管理粗放",
          type: "html",
          text: "用车实况无法及时掌控，用车<br>效率无法估算",
        },
        {
          url: require("@/assets/images/solution/details8/point3.png"),
          title: "费用虚高",
          type: "html",
          text: "虚假报帐，油费、维修费居高<br>不下，造车企业经营成本上升",
        },
        {
          url: require("@/assets/images/solution/details8/point4.png"),
          title: "安全难保障",
          type: "html",
          text: "疲劳驾驶、超速、急加速等危<br>险驾驶行为，车辆维修保养不<br>及时等，易造成不良后果",
        },
      ],
      cardList: [
        {
          url: require("@/assets/images/solution/details8/card1.png"),
          text: "公务车",
          width: "222px",
          height: "160px",
        },
        {
          url: require("@/assets/images/solution/details8/card2.png"),
          text: "商务车",
          width: "222px",
          height: "160px",
        },
        {
          url: require("@/assets/images/solution/details8/card3.png"),
          text: "中巴车",
          width: "222px",
          height: "160px",
        },
        {
          url: require("@/assets/images/solution/details8/card4.png"),
          text: "大巴车",
          width: "222px",
          height: "160px",
        },
      ],
      resolutionList: [
        {
          title: "用车申请及审批",
          text: "公车公用申请、私车公用申请，查看车辆定位、查看车辆使用情况。<br>单级审批、多级审批，审批提醒、查看审批详情、查看审批记录。",
          url: require("@/assets/images/solution/details8/r0.png"),
          icon: require("@/assets/images/solution/details8/icon1.png"),
        },
        {
          title: "排班调度",
          text: "智能调度派车，有效提高车辆利用率，提高运营效率，减少人工成本及时间浪费。车辆调度人员可根据司机/车辆实时状态调度，避免发生任务期冲突。",
          url: require("@/assets/images/solution/details8/r1.png"),
          icon: require("@/assets/images/solution/details8/icon2.png"),
        },
        {
          title: "车辆监管",
          text: "定位监控、防公车私用、提供私车公用报销凭证。实时定位、就近派单、查看轨迹、统计里程、行驶状态、油耗监控、任务中与非任务中管控、电子围栏。",
          url: require("@/assets/images/solution/details8/r2.png"),
          icon: require("@/assets/images/solution/details8/icon3.png"),
        },
        {
          title: "视频监控",
          text: "通过硬件设备、云端后台、大数据算法三大核心技术，为车辆提供实时定位追踪、安全视频监控、行车轨迹回溯、多重告警通知、司机驾驶行为汇总等数据存档及图表分析。",
          url: require("@/assets/images/solution/details8/r3.png"),
          icon: require("@/assets/images/solution/details8/icon4.png"),
        },
        {
          title: "统计报表",
          text: "排班报表、行程报表。自动生成报表、数据导出、多维度统计分析。",
          url: require("@/assets/images/solution/details8/r4.png"),
          icon: require("@/assets/images/solution/details8/icon5.png"),
        },
        {
          title: "车务管理",
          text: "信息管理、保养提醒、年检提醒、保险提醒。全方位车务信息管理、周期性自动提醒、短信或APP提醒、设置其他提醒、维保管理、事故管理、费用管理。",
          url: require("@/assets/images/solution/details8/r5.png"),
          icon: require("@/assets/images/solution/details8/icon6.png"),
        },
        {
          title: "财务结算",
          text: "系统对司机及车辆多项费用进行汇总统计分析，通过数据统计更直观反映整体费用情况。费用录入如加油、保养、过路、修理、罚款、配件等，自动统计报表。公车公用费用分摊、私车公用费用报销、用车收入、司机费用、车务费用等。",
          url: require("@/assets/images/solution/details8/r5.png"),
          icon: require("@/assets/images/solution/details8/icon6.png"),
        },
      ],
      swiperList: [
        {
          url: require("@/assets/images/solution/details8/s1.png"),
          title: "浙江大学",
          html: `公车管理、排班调度、用车申请等，公务车辆的高效高质量管理。`,
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2c2c2c;
  letter-spacing: 1px;
  margin: 40px 0;
}
.line {
  margin-top: 40px;
  width: 960px;
  height: 0px;
  border: 1px solid #dbdbdb;
}
.text {
  width: 960px;
  height: 60px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 24px;
}
.img2 {
  margin: 112px 0;
  width: 960px;
  height: 202px;
}
</style>
