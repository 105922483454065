<!--
 * @Author: 小洋人
 * @Date: 2024-07-31 17:10:44
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-08-02 19:41:14
 * @FilePath: \kangmisi-officialwebsite\src\views\components\components\light.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
    <div>
      <div class="list">
        <div v-for="(item, index) in list" :key="index" class="item">
          <img class="img" :src="item.url" alt=""  :style="{height:item.px,width:item.px}" />
          <div>
            {{ item.title }}
          </div>
          <div v-html="item.text" class="text" >
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: {
      list: {
        type: Array,
      },
    },
    data() {
      return {};
    },
  };
  </script>
  <style lang="less" scoped>
  .list {
    display: flex;
    flex-wrap: wrap;
    &>:not(:last-child){
        margin-right: 24px;
    }
  }
  .item {
    width: 304px;
    height: 327px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.16);
    // margin-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    margin-bottom:32px;
  }
  .img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 48px;
    margin-left: 24px;
    height:96px;
    width: 96px;
  }
  .title {
    font-weight: bold;
    font-size: 20px;
    color: #2c2c2c;
    margin-top: 16px;
  }
  img {
    width: 96px;
    height: 64px;
    margin-left:16px;
  }
  .text {
    margin-top: 16px;
    font-weight: 300;
    font-size: 16px;
    color: #2c2c2c;
    padding-top: 5px;
    width: 272px;
  }
  </style>