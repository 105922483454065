<!--
 * @Author: 小洋人
 * @Date: 2024-07-31 16:14:10
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-07-31 18:14:44
 * @FilePath: \kangmisi-officialwebsite\src\views\components\details1001.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<!--
 * @Author: 小洋人
 * @Date: 2024-07-12 11:41:57
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-07-16 17:16:22
 * @FilePath: \kangmisi-officialwebsite\src\views\components\details1.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div class="pages">
    <div class="title">人员位置管理</div>
    <div class="desc">
      康米斯致力于为外勤人员和室内人员提供全方位的位置服务。通过精准的定位技术和智能化后台系统，能够实时查询员工的位置、考勤记录和作业轨迹，让企业随时随地了解作业人员的动态。紧急求助功能，保障在紧急情况下员工能够及时获得帮助；下班后一键休眠，不侵犯员工个人隐私，所有定位信息均受到严格的加密和权限控制，确保信息安全。通过康米斯人员位置管理，可以实现人员作业更高效，遇险抢救更及时。
    </div>
    <div class="title1">外勤人员</div>
    <div class="desc" style="border-bottom: none">
      智慧外勤IoT管理解决方案，以物联网智能终端作为通讯入口，采集外勤员工状态（位置、轨迹、告警信息、车内外监控等）。
    </div>
    <img
      src="@/assets/images/solution/details1001/img1.png"
      alt=""
      class="img1"
    />
    <div class="desc">
      应用场景：环卫、电力巡检、水务巡检、燃气巡检、安保巡检、通信巡检、森林巡检、道路养护、保险勘察
    </div>
    <div class="title1">室内人员</div>
    <div class="fangan1">定位方案1：蓝牙信标</div>
    <div class="fang-list">
      <div class="item">
        <div class="item-title">方案简介</div>
        <div class="item-con">
          蓝牙信标的定位方案可按需实现<span>3~5m、5~8m、存在性检测</span>3种定位精度，同时结合自身:免综合布线、续航长、易维护、价格低廉等多项优势成为了应用最广的方案。
        </div>
      </div>
      <div class="item">
        <div class="item-title">方案链路</div>
        <div class="item-con">
          信标持续播发低频广播信号，标签接收数据后结合相对位置，输出原始位置信息至网关，网关汇总标签数据后通过有线或无线传至解算服务器进行最终数据呈现。
        </div>
      </div>
      <div class="item">
        <div class="item-title">方案优势</div>
        <div class="item-con">
          <div class="tem-con1">施工便捷</div>
          <div class="tem-con1">精度按需分配</div>
          <div class="tem-con1">标签样式多样化</div>
          <div class="tem-con2">成熟度高(90%以上的终端客户选择)</div>
        </div>
      </div>
    </div>
    <img
      src="@/assets/images/solution/details1001/img2.png"
      alt=""
      class="img2"
    />
    <div class="hang">
      <span>适合行业：</span>
      交通枢纽、医院养老、仓储物流、智慧楼宇、会展中心
    </div>
    <div class="fang2">定位方案2：蓝牙AOA</div>
    <div class="fang2-list">
      <div
        v-for="(item, index) in fangList"
        :key="index"
        class="fang2-list-item"
      >
        <img :src="item.url" alt="" />
        <div class="fang2-name">{{ item.name }}</div>
        <div class="fang2-text1" v-if="item.text1">{{ item.text1 }}</div>
        <div v-else class="fang2-text2">
          <div class="time">续航时间:</div>
          <div>
            <div class="time1">标准模式:8天</div>
            <div class="time1">长续航模式:14天</div>
          </div>
        </div>
        <div class="fang2-text1">{{ item.text2 }}</div>
        <div class="fang2-text1">{{ item.text3 }}</div>
        <div class="fang2-text1" v-if="item.text4">{{ item.text4 }}</div>
        <div v-else class="fang2-text2" >
          <div class="time">续航时间:</div>
          <div>
            <div class="time1">标准模式:8天</div>
            <div class="time1">长续航模式:14天</div>
          </div>
        </div>
      </div>
    </div>
    <div class="hang" style="margin-top: 8px">
      <span>适合行业：</span>
      智慧文旅、智慧商业、智慧停车、智慧园区、石油化工
    </div>
    <div class="fangan3">定位方案3：UWB高精度定位</div>
    <div class="fang3-list">
      <div class="fang3-text">
        <div class="fang3-tiem">
          <span>带宽高：</span>UWB信号的带宽非常 宽，可以达到纳秒级，这使得它
          具有很高的时间分辨率。
        </div>
        <div class="fang3-tiem">
          <span>脉冲窄：</span>UWB信号的脉冲非常 窄，一般在几个纳秒以内，窄脉
          冲还具有更好的抗多径干扰能 力。
        </div>
        <div class="fang3-tiem">
          UWB的定位精度还可以通过高精 度UWB定位算法进一步优化，达
          到<span>厘米级别</span>的定位精度。
        </div>
      </div>
      <div class="img7">
        <img src="@/assets/images/solution/details1001/img7.png" alt="" />
      </div>
    </div>
    <div class="hang" style="margin-top: 6px; margin-bottom: 200px">
      <span>应用场景：</span>
      数字文旅、公检法司、智慧停车、交通枢纽、智慧医院、智慧工厂、仓储物流、电力能源、矿山隧道、智慧楼宇
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fangList: [
        {
          url: require("@/assets/images/solution/details1001/img3.png"),
          name: "手环型标签",
          text1: "",
          text2: "防护等级: IP67",
          text3: "充电接口: 磁吸",
          text4: "",
        },
        {
          url: require("@/assets/images/solution/details1001/img4.png"),
          name: "蓝牙定位标签",
          text1: "电池种类: 一次性电池",
          text2: "工作温度: 一次性电池",
          text3: "防护等级: IP68",
          text4: "供电续航: 内置电池，续航3~5年",
        },
        {
          url: require("@/assets/images/solution/details1001/img5.png"),
          name: "工牌标签",
          text1: "续航时间: 10~15天",
          text2: "防护等级: IP67",
          text3: "充电接口: Type-c",
          text4: "支持SOS一键报警",
        },
        {
          url: require("@/assets/images/solution/details1001/img6.png"),
          name: "AOA 定位基站",
          text1: "数据接口: 标准以太网",
          text2: "电源接口: POE",
          text3: "定位精度: 1m",
          text4: "工作温度: -20-70℃",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.pages {
  //   background-color: pink;
}
.title {
  font-size: 24px;
  color: #2c2c2c;
  margin-top: 56px;
  margin-bottom: 16px;
}
.desc {
  font-weight: 300;
  font-size: 14px;
  color: #2c2c2c;
  line-height: 20px;
  padding-bottom: 24px;
  border-bottom: 1px solid #dbdbdb;
}
.title1 {
  font-weight: 400;
  font-size: 18px;
  color: #2c2c2c;
  margin-bottom: 16px;
  margin-top: 32px;
}
.img1 {
  width: 960px;
  height: 420px;
  margin-bottom: 24px;
}
.fangan1 {
  height: 21px;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 16px;
}
.fang-list {
  display: flex;
  justify-content: space-around;
}
.item {
  width: 316px;
  .item-title {
    height: 64px;
    background: #8197b5;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 64px;
    text-align: center;
  }
  .item-con {
    font-weight: 300;
    font-size: 14px;
    color: #2c2c2c;
    line-height: 32px;
    width: 316px;
    height: 166px;
    padding: 16px;
    background: #f8f8f8;
    box-sizing: border-box;
    margin-top: 6px;

    span {
      font-weight: bold;
      font-size: 14px;
      color: #0f3391;
    }
  }
  .tem-con2 {
    font-weight: bold;
    font-size: 14px;
    color: #0f3391;
  }
}
.img2 {
  width: 960px;
  height: 347px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.hang {
  width: 960px;
  height: 64px;
  background: #f8f8f8;
  line-height: 64px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  box-sizing: border-box;
  padding-left: 24px;
  span {
    font-weight: bold;
    font-size: 16px;
    color: #2c2c2c;
  }
}
.fang2 {
  margin-top: 32px;
  font-size: 16px;
  color: #2c2c2c;
  margin-bottom: 16px;
}
.fang2-list {
  display: flex;
  justify-content: space-around;
  .fang2-list-item {
    width: 234px;
    img {
      width: 234px;
      height: 220px;
      border: 1px dashed #b2b2b2;
      background-color: #fafafa;
    }
    .fang2-name {
      width: 236px;
      height: 56px;
      background: #8197b5;
      padding-left: 16px;
      line-height: 56px;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      margin-top: 8px;
      box-sizing: border-box;
    }
    .fang2-text1 {
      width: 236px;
      height: 48px;
      background: #f8f8f8;
      font-size: 14px;
      color: #2c2c2c;
      line-height: 48px;
      padding-left: 16px;
      margin-top: 8px;
      box-sizing: border-box;
    }
    .fang2-text2 {
      width: 236px;
      background: #f8f8f8;
      margin-top: 8px;
      box-sizing: border-box;
      height: 48px;
      display: flex;
      padding-top: 4px;
      .time {
        font-size: 14px;
        color: #2c2c2c;
        padding-left: 16px;
      }
      .time1 {
        font-size: 14px;
        color: #2c2c2c;
        padding-left: 8px;
      }
    }
  }
}
.fangan3 {
  margin-top: 32px;
  margin-bottom: 16px;
}
.fang3-list {
  display: flex;
  justify-content: space-between;
  .fang3-text {
    width: 264px;
    height: 448px;
    background: #f8f8f8;
    padding: 16px 32px;
    box-sizing: border-box;
  }
  .fang3-tiem {
    width: 196px;
    font-weight: 300;
    font-size: 14px;
    color: #2c2c2c;
    line-height: 24px;
    margin-top: 48px;
    span {
      font-weight: 700;
    }
  }
}
.img7 {
  width: 690px;
  height: 448px;
  img {
    width: 690px;
    height: 448px;
  }
}
</style>