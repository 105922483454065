<!--
 * @Author: 小洋人
 * @Date: 2024-07-31 16:26:20
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-08-08 19:16:52
 * @FilePath: \kangmisi-officialwebsite\src\views\components\components\wayPoint.vue
 * @email: 1172457354@qq.com
 * @Description: 行业痛点
-->
<template>
  <div>
    <div class="list">
      <div v-for="(item, index) in list" :key="index" class="item">
        <div class="img">
          <img :src="item.url" alt="" :style="{width:item.title?'64px':'96px',height:item.title?'64px':'96px'}"/>
          <div class="title">{{ item.title }}</div>
        </div>
        <div class="html" v-if="item.type == 'html'" v-html="item.text"></div>
        <div class="text" v-else>{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
}
.item {
  width: 222px;
  height: 264px;
  margin-right: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin-bottom:24px;
}
.img {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.title {
  font-weight: bold;
  font-size: 16px;
  color: #2c2c2c;
  margin-bottom: 16px;
}
img {
  width: 64px;
  height: 64px;
  margin: 24px 0px 16px 0;
}
.text {
  padding: 0 16px;
  font-weight: 300;
  font-size: 14px;
  color: #2c2c2c;
  line-height: 20px;
}
.item:nth-child(4n) {
  margin-right: 0px;
}
.html{
  font-weight: 300;
  font-size: 14px;
  color: #2c2c2c;
  line-height: 20px;
  text-align: center;
}
</style>