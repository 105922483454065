<!--
 * @Author: 小洋人
 * @Date: 2024-07-12 11:41:57
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-08-08 19:05:18
 * @FilePath: \kangmisi-officialwebsite\src\views\components\details1.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div class="pages">
    <div class="title">应用场景</div>
    <card :cardList="cardList"></card>
    <div class="line"></div>
    <div class="title">行业痛点</div>
    <wayPoint :list="pointList"></wayPoint>
    <div class="line"></div>
    <div class="title" style="margin-bottom: 24px">康米斯解决方案</div>
    <div class="text">
      智慧外勤IoT管理解决方案，以物联网智能终端作为通讯入口，采集外勤员工状态(位置、轨迹、告警信息、车内外监控等)一方面管理者可高效监管外勤员工，实现智能调度、协同作业、无纸化考核、高效排班，以优化管理成本，另一方面外勤员工可通过电子工牌实现智能打卡、危险情况一键求救等，还可以对接到客户管理平台。
    </div>
    <img src="@/assets/images/solution/details1/img.png" alt="" />
    <div class="line"></div>
    <div class="title">方案亮点</div>
    <light :list="lightList"></light>
    <div class="line"></div>
    <div class="title">功能分工</div>
    <work :list="workList"></work>
    <div class="line"></div>
    <div class="title">客户案例</div>
    <swiper :list="swiperList"></swiper>
    <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
  </div>
</template>
<script>
import card from "./components/card.vue";
import wayPoint from "./components/wayPoint.vue";
import light from "./components/light.vue";
import work from "./components/work.vue";
import swiper from "./components/swiper.vue";
export default {
  components: {
    wayPoint,
    card,
    light,
    work,
    swiper,
  },
  data() {
    return {
      swiperList: [
        {
          url: require("@/assets/images/solution/details1/s1.png"),
          title: "国家电网",
          html: `帮助国家电网<span style='color: #0F3391;font-weight: Bold;'>30000+</span>巡检人员实现数字化管理，提高巡检工作效率，故障维修处理，<span style='color: #0F3391;font-weight: Bold;'>2600</span>多辆巡检车，解决了公车私用的问题。`,
        },
        {
          url: require("@/assets/images/solution/details1/s2.png"),
          title: "杭州环卫",
          html: `上城区、拱墅区、临安、余杭区等区县<span style='color: #0F3391;font-weight: Bold;'>5000+</span>环卫工人定位管理，考勤管理、排班调度。`,
        },
        {
          url: require("@/assets/images/solution/details1/s3.png"),
          title: "中国移动",
          html: `<span style='color: #0F3391;font-weight: Bold;'>2000+</span>中国移动通信巡检人员定位管理，考勤管理、排班调度。`,
        },
        {
          url: require("@/assets/images/solution/details1/s4.png"),
          title: "围场满族蒙古族自治县森林草原防灭火保障中心",
          html: `<span style='color: #0F3391;font-weight: Bold;'>2000+</span>森林巡检人员定位管理，考勤管理、排班调度，提高作业效率。`,
        },
      ],
      workList: [
        {
          title: "电子工牌",
          list: [
            {
              title: "定位考勤数据上报",
              url: require("@/assets/images/solution/details1/work1.png"),
              text: "卡片机随身携带即可完成自动考勤打卡,同时也可以通过按键驻点打卡。设备将定位数据和考勤数据传输给APP",
            },
            {
              title: "预警状态上报",
              url: require("@/assets/images/solution/details1/work3.png"),
              text: "设备定位数据通过大数据平台将预警信息发送到APP",
            },
            {
              title: "通话求助",
              url: require("@/assets/images/solution/details1/work5.png"),
              text: "可以设置SOS报警，发生意外及时反馈",
            },
          ],
        },
        {
          title: "智位星APP",
          list: [
            {
              title: "排班调拨",
              url: require("@/assets/images/solution/details1/work2.png"),
              text: "管理人员通过APP设置排班信息后通过数据接口发送至服务器用以定位打卡",
            },
            {
              title: "信息资料查看",
              url: require("@/assets/images/solution/details1/work4.png"),
              text: "个人信息，排班信息，打卡信息，驻派信息，通讯录查看。",
            },
            {
              title: "审批申请",
              url: require("@/assets/images/solution/details1/work6.png"),
              text: "请假，调班，补卡等申请，以及申请查看和批复。",
            },
          ],
        },
      ],
      lightList: [
        {
          url: require("@/assets/images/solution/details1/light1.png"),
          title: "规范出勤",
          px: "64px",
          text: "一键打卡，结合现场拍照，保证按时出勤",
        },
        {
          url: require("@/assets/images/solution/details1/light2.png"),
          title: "保证在岗",
          px: "64px",
          text: "随时查看员工当前位置，紧急工作就近安排，拒绝脱岗",
        },
        {
          url: require("@/assets/images/solution/details1/light3.png"),
          title: "保证出差有效",
          px: "64px",
          text: "自动显示真实工作轨迹，防止工作时间放羊",
        },
        {
          url: require("@/assets/images/solution/details1/light4.png"),
          title: "定人定岗",
          px: "64px",
          text: "自定义员工围栏区域，脱岗即提醒，避免脱离工作区域",
        },
        {
          url: require("@/assets/images/solution/details1/light5.png"),
          title: "防止作弊",

          text: "全方位智能分析定位失联原因，考核有根据，客观公平公正",
        },
        {
          url: require("@/assets/images/solution/details1/light6.png"),
          title: "人性化管理",
          px: "64px",
          text: "人性化设置休息时间，上班不偷懒，下班有隐私",
        },
      ],
      pointList: [
        {
          url: require("@/assets/images/solution/details1/point1.png"),
          title: "管理效率低",
          text: "外勤人员及车辆数量多、分布广，如果考勤定时定点，容易出现考勤困难、难以精确调配、难以协同作业等现象，人工管理亦费时费力。",
        },
        {
          url: require("@/assets/images/solution/details1/point2.png"),
          title: "考核有困难",
          text: "外勤员工及车辆作业考核无可信数据支撑，易出现考核不公等情况，引发内部矛盾。",
        },
        {
          url: require("@/assets/images/solution/details1/point3.png"),
          title: "安全难保障",
          text: "外勤人员长期在户外工作，面临严寒酷暑、交通事故等威胁;而环卫/巡逻等车辆若不能及时发现故障，也容易造成生命及财产损失。",
        },
        {
          url: require("@/assets/images/solution/details1/point4.png"),
          title: "作业监管难",
          text: "外勤工作如环卫作业，环节繁多，难以对每个人员及相关车辆进行监管，随时掌控作业动态，也无法形成有效数据，优化作业成本并提高工作效率。",
        },
      ],
      cardList: [
        {
          url: require("@/assets/images/solution/details1/card1.png"),
          text: "环卫",
        },
        {
          url: require("@/assets/images/solution/details1/card2.png"),
          text: "电力",
        },
        {
          url: require("@/assets/images/solution/details1/card3.png"),
          text: "水务",
        },
        {
          url: require("@/assets/images/solution/details1/card4.png"),
          text: "燃气",
        },
        {
          url: require("@/assets/images/solution/details1/card5.png"),
          text: "通信",
        },
        {
          url: require("@/assets/images/solution/details1/card6.png"),
          text: "安保",
        },
        {
          url: require("@/assets/images/solution/details1/card7.png"),
          text: "绿化",
        },
        {
          url: require("@/assets/images/solution/details1/card8.png"),
          text: "路桥养护",
        },
        {
          url: require("@/assets/images/solution/details1/card9.png"),
          text: "保险勘察",
        },
        {
          url: require("@/assets/images/solution/details1/card10.png"),
          text: "森林巡检",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2c2c2c;
  letter-spacing: 1px;
  margin: 40px 0;
}
.line {
  margin-top: 40px;
  width: 960px;
  height: 0px;
  border: 1px solid #dbdbdb;
}
.text {
  width: 960px;
  height: 60px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 24px;
}
.img2 {
  margin: 112px 0;
  width: 960px;
  height: 202px;
}
</style>