
<template>
    <div class="pages">
      <div class="title">应用场景</div>
      <card :cardList="cardList"></card>
      <div class="line"></div>
      <div class="title">行业痛点</div>
      <wayPoint :list="pointList"></wayPoint>
      <div class="line"></div>
      <div class="title" style="margin-bottom: 24px">康米斯解决方案</div>
      <resolution :resolution-list="resolutionList"></resolution>
      <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
    </div>
  </template>
  <script>
  import card from "./components/card.vue";
  import wayPoint from "./components/wayPoint.vue";
  import resolution from "./components/resolution.vue";
  export default {
    components: {
      wayPoint,
      card,
      resolution,
    },
    data() {
      return {
        pointList: [
          {
            url: require("@/assets/images/solution/details9/point1.png"),
            title: "",
            type: "html",
            text: `车辆的工作状态难监控,故障无<br>法及时获知,无法进行合理维修`,
          },
          {
            url: require("@/assets/images/solution/details9/point2.png"),
            title: "",
            type: "html",
            text: "车辆费用居高不下,油耗监控难",
          },
          {
            url: require("@/assets/images/solution/details9/point3.png"),
            title: "",
            type: "html",
            text: "司机是否按照正确行驶路线无<br>法确定,活动轨迹无法追溯",
          },
          {
            url: require("@/assets/images/solution/details9/point4.png"),
            title: "",
            type: "html",
            text: "司机驾驶操作行为难监控，疲<br>劳驾驶容易引发事故",
          },
          {
            url: require("@/assets/images/solution/details9/point5.png"),
            title: "",
            type: "html",
            text: "出车趟数难以统计",
          },
          {
            url: require("@/assets/images/solution/details9/point6.png"),
            type: "html",
            title: "",
            text: "合理调度难,无法控制进度<br>利用率低下",
          },
        ],
        cardList: [
          {
            url: require("@/assets/images/solution/details9/card1.png"),
            text: "渣土车",
          },
          {
            url: require("@/assets/images/solution/details9/card2.png"),
            text: "叉车",
          },
          {
            url: require("@/assets/images/solution/details9/card3.png"),
            text: "挖掘机",
          },
          {
            url: require("@/assets/images/solution/details9/card4.png"),
            text: "推土机",
          },
          {
            url: require("@/assets/images/solution/details9/card5.png"),
            text: "清障车",
          },
          {
            url: require("@/assets/images/solution/details9/card4.png"),
            text: "升降机",
          },
          {
            url: require("@/assets/images/solution/details9/card5.png"),
            text: "铲车",
          },
        ],
        resolutionList: [
          {
            title: "车辆监控",
            text: "场景：利用车辆定位、视频监控等设备，实现任务全程可视化，大幅<br>提升工程车的作业效率。<br>功能：地图测距、添加电子围栏、路径规划、实时定位、查看轨迹、统计里程、行驶状态、驾驶行为分析；实时视频、路况检测，趟数统计等。",
            url: require("@/assets/images/solution/details9/r0.png"),
            icon: require("@/assets/images/solution/details9/icon1.png"),
          },
          {
            title: "电子围栏",
            text: "场景：管理员通过电子围栏安排车辆在指定区域内作业，若车辆超出预设好的半径，系统将报警提醒管理员。<br>功能：定位监控、路径规划、智能报警功能。",
            url: require("@/assets/images/solution/details9/r1.png"),
            icon: require("@/assets/images/solution/details9/icon2.png"),
          },
          {
            title: "ADAS/DSM/BSD安全管理",
            text: "通过车载终端、视频产品，对车辆及司机的安全情况进行实时监管。<br>车道偏移/频发变道/实线变道/盲区/疲劳/分神/打瞌睡/接电话/玩手<br>机/抽烟。",
            url: require("@/assets/images/solution/details9/r2.png"),
            icon: require("@/assets/images/solution/details9/icon3.png"),
          },
          {
            title: "油耗管理",
            text: "油耗：采集油量数据，实时上报，上报加油、油量骤减监控用量异常；同类型车辆能耗比对，形成节能潜力分析报告。",
            url: require("@/assets/images/solution/details9/r3.png"),
            icon: require("@/assets/images/solution/details9/icon4.png"),
          },
          {
            title: "费用管理",
            text: "场景：管理员利用财务报表进行费用结算，对上传凭证进行车务费用报销、司机费用结算等。<br>功能：自动计算任务费用、费用审批、费用录入、上传/核对报销凭证、数据导出、自动生成报表。",
            url: require("@/assets/images/solution/details9/r4.png"),
            icon: require("@/assets/images/solution/details9/icon5.png"),
          },
        
          {
            title: "统计报表",
            text: "场景：管理员根据系统生成的出车趟数统计报表，结算司机和客户费用。<br>功能：趟数统计报表、数据明细导出、费用结算。",
            url: require("@/assets/images/solution/details9/r5.png"),
            icon: require("@/assets/images/solution/details9/icon6.png"),
          },
        ],
      };
    },
    mounted() {},
    methods: {},
  };
  </script>
  <style lang="less" scoped>
  .title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #2c2c2c;
    letter-spacing: 1px;
    margin: 40px 0;
  }
  .line {
    margin-top: 40px;
    width: 960px;
    height: 0px;
    border: 1px solid #dbdbdb;
  }
  .text {
    width: 960px;
    height: 60px;
    font-weight: 300;
    font-size: 16px;
    color: #2c2c2c;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .img2 {
    margin: 112px 0;
    width: 960px;
    height: 202px;
  }
  </style>
  