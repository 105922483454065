
<template>
  <div>
    <div class="list">
      <div v-for="(item, index) in list" :key="index" class="item">
        <img :src="item.url" alt=""  :style="{height:item.px,width:item.px}" />
        <div class="img">
          <div class="title">{{ item.title }}</div>
          <div v-if="item.type=='html'" v-html="item.text" class="text" :style="{width:item.textWidth}" ></div>
          <div v-else class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
}
.item {
  width: 468px;
  height: 208px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.16);
  margin-right: 24px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
  margin-bottom:32px;
}
.img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // margin-top: 36px;
  margin-left: 24px;
  height: 152px;
}
.title {
  font-weight: bold;
  font-size: 18px;
  color: #2c2c2c;
}
img {
  width: 64px;
  height: 64px;
  margin-left:16px;
}
.text {
  font-weight: 300;
  font-size: 14px;
  color: #2c2c2c;
  padding-top: 5px;
  width: 224px;
}
.item:nth-child(2n) {
  margin-right: 0px;
}
</style>