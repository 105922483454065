var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pages"},[_c('div',{staticClass:"title"},[_vm._v("应用场景")]),_c('card',{attrs:{"cardList":_vm.cardList}}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"title"},[_vm._v("行业痛点")]),_c('wayPoint',{attrs:{"list":_vm.pointList}}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"title"},[_vm._v("康米斯解决方案")]),_c('div',{staticClass:"fangan1"},[_vm._v("定位方案1：蓝牙信标")]),_vm._m(0),_c('img',{staticClass:"img2",attrs:{"src":require("@/assets/images/solution/details2/img7.png"),"alt":""}}),_vm._m(1),_c('div',{staticClass:"line"}),_c('div',{staticClass:"fang2"},[_vm._v("定位方案2：蓝牙AOA")]),_c('div',{staticClass:"fang2-list"},_vm._l((_vm.fangList),function(item,index){return _c('div',{key:index,staticClass:"fang2-list-item"},[_c('img',{attrs:{"src":item.url,"alt":""}}),_c('div',{staticClass:"fang2-name"},[_vm._v(_vm._s(item.name))]),(item.text1)?_c('div',{staticClass:"fang2-text1"},[_vm._v(_vm._s(item.text1))]):_c('div',{staticClass:"fang2-text2"},[_c('div',{staticClass:"time"},[_vm._v("续航时间:")]),_vm._m(2,true)]),_c('div',{staticClass:"fang2-text1"},[_vm._v(_vm._s(item.text2))]),_c('div',{staticClass:"fang2-text1"},[_vm._v(_vm._s(item.text3))]),(item.text4)?_c('div',{staticClass:"fang2-text1"},[_vm._v(_vm._s(item.text4))]):_c('div',{staticClass:"fang2-text2"},[_c('div',{staticClass:"time"},[_vm._v("续航时间:")]),_vm._m(3,true)])])}),0),_vm._m(4),_c('div',{staticClass:"line"}),_c('div',{staticClass:"fangan3"},[_vm._v("定位方案3：UWB高精度定位")]),_vm._m(5),_vm._m(6),_c('div',{staticClass:"line"}),_c('div',{staticClass:"title"},[_vm._v("功能分工")]),_c('functionWrok',{attrs:{"list":_vm.functionWrokList}}),_c('img',{staticClass:"img3",attrs:{"src":require("@/assets/images/solution/details1/img2.png"),"alt":""}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fang-list"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("方案简介")]),_c('div',{staticClass:"item-con"},[_vm._v(" 蓝牙信标的定位方案可按需实现"),_c('span',[_vm._v("3~5m、5~8m、存在性检测")]),_vm._v("3种定位精度，同时结合自身:免综合布线、续航长、易维护、价格低廉等多项优势成为了应用最广的方案。 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("方案链路")]),_c('div',{staticClass:"item-con"},[_vm._v(" 信标持续播发低频广播信号，标签接收数据后结合相对位置，输出原始位置信息至网关，网关汇总标签数据后通过有线或无线传至解算服务器进行最终数据呈现。 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("方案优势")]),_c('div',{staticClass:"item-con"},[_c('div',{staticClass:"tem-con1"},[_vm._v("施工便捷")]),_c('div',{staticClass:"tem-con1"},[_vm._v("精度按需分配")]),_c('div',{staticClass:"tem-con1"},[_vm._v("标签样式多样化")]),_c('div',{staticClass:"tem-con2"},[_vm._v("成熟度高(90%以上的终端客户选择)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hang"},[_c('span',[_vm._v("适合行业：")]),_vm._v(" 交通枢纽、医院养老、仓储物流、智慧楼宇、会展中心 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"time1"},[_vm._v("标准模式:8天")]),_c('div',{staticClass:"time1"},[_vm._v("长续航模式:14天")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"time1"},[_vm._v("标准模式:8天")]),_c('div',{staticClass:"time1"},[_vm._v("长续航模式:14天")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hang",staticStyle:{"margin-top":"8px"}},[_c('span',[_vm._v("适合行业：")]),_vm._v(" 智慧文旅、智慧商业、智慧停车、智慧园区、石油化工 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fang3-list"},[_c('div',{staticClass:"fang3-text"},[_c('div',{staticClass:"fang3-tiem"},[_c('span',[_vm._v("带宽高：")]),_vm._v("UWB信号的带宽非常 宽，可以达到纳秒级，这使得它 具有很高的时间分辨率。 ")]),_c('div',{staticClass:"fang3-tiem"},[_c('span',[_vm._v("脉冲窄：")]),_vm._v("UWB信号的脉冲非常 窄，一般在几个纳秒以内，窄脉 冲还具有更好的抗多径干扰能 力。 ")]),_c('div',{staticClass:"fang3-tiem"},[_vm._v(" UWB的定位精度还可以通过高精 度UWB定位算法进一步优化，达 到"),_c('span',[_vm._v("厘米级别")]),_vm._v("的定位精度。 ")])]),_c('div',{staticClass:"img7"},[_c('img',{attrs:{"src":require("@/assets/images/solution/details1001/img7.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hang",staticStyle:{"margin-top":"6px","margin-bottom":"60px"}},[_c('span',[_vm._v("应用场景：")]),_vm._v(" 数字文旅、公检法司、智慧停车、交通枢纽、智慧医院、智慧工厂、仓储物流、电力能源、矿山隧道、智慧楼宇 ")])
}]

export { render, staticRenderFns }