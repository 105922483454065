<!--
 * @Author: 小洋人
 * @Date: 2024-08-07 15:12:04
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-08-08 19:00:54
 * @FilePath: \kangmisi-officialwebsite\src\views\components\details13.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div class="pages">
    <div class="title">应用场景</div>
    <card :cardList="cardList"></card>
    <div class="line"></div>
    <div class="title">行业痛点</div>
    <wayPoint :list="pointList"></wayPoint>
    <div class="line"></div>
    <div class="title">方案架构</div>
    <img
      src="@/assets/images/solution/details13/img3.png"
      alt=""
      style="width: 960px; height: 467px"
    />
    <div class="line"></div>
    <div class="title">康米斯解决方案</div>
    <fang :list="fangList"></fang>
    <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
  </div>
</template>
<script>
import card from "./components/card-num.vue";
import wayPoint from "./components/wayPoint.vue";
import fang from "./components/fang.vue";
export default {
  components: {
    wayPoint,
    card,
    fang,
  },
  data() {
    return {
      fangList: [
        {
          url: require("@/assets/images/solution/details13/fang1.png"),
          title: "货物开箱报警",
          text: "智能终端配有光敏传感器，运输途中包装被异常打开平台提示开箱报警，帮助客户从源头至末端全程保障商品的安全可靠性。",
        },
        {
          url: require("@/assets/images/solution/details13/fang2.png"),
          title: "运输过程监管",
          text: "实时定位追踪，轨迹查询功能配置GPS/北斗定位设备，可查看货物实时分布情况，保证运输时效。",
        },
        {
          url: require("@/assets/images/solution/details13/fang3.png"),
          title: "运输时效监管",
          text: "运输运距长，过程复杂，容易造成订单失联，运输信息不透明。智能定位终端绑定货物，运输全程可视化，实时追踪，运输路线节点通知，货物超时自动预警，保障货物交货时效。",
        },
        {
          url: require("@/assets/images/solution/details13/fang4.png"),
          title: "运输路径监管",
          text: "通过在途位置监控和电子围栏，企业可以对运输路线进行定点监督，有效规范运输路线，防止串货，加强物流时效性监管。",
        },
      ],
      pointList: [
        {
          url: require("@/assets/images/solution/details13/point1.png"),
          title: "货物/资产追踪难",
          text: "市面上的车载定位器往往面向车辆，无法对货物/资产进行全程追踪，一旦货物/资产多级运转、分流运输就可能失去追踪记录",
        },
        {
          url: require("@/assets/images/solution/details13/point2.png"),
          title: "问题责任追溯难",
          text: "当出现货物丢失、损坏、偷换等意外情况时，由于货物资产流通特性，难以迅速定位责任方",
        },
        {
          url: require("@/assets/images/solution/details13/point3.png"),
          title: "解决方案-资产-包装设备",
          text: "统计跟进依靠人工，容易遗漏，信息化程度不高",
        },
        {
          url: require("@/assets/images/solution/details13/point4.png"),
          title: "偷货、窜货抑制难",
          text: "偷货、窜货乱象给生产企业带来很大的经济损失，如何实现商品在流通各个环节的跟踪与监控尤为重要",
        },
        {
          url: require("@/assets/images/solution/details13/point5.png"),
          title: "运营成本降低难",
          text: "运输过程中产生不必要开支，企业无法降低运营成本，提高资源利用率",
        },
      ],
      cardList: [
        {
          url: require("@/assets/images/solution/details13/card1.png"),
          text: "料架",
          width: "222px",
          height: "160px",
        },
        {
          url: require("@/assets/images/solution/details13/card2.png"),
          text: "网箱/周转箱",
          width: "222px",
          height: "160px",
        },
        {
          url: require("@/assets/images/solution/details13/card3.png"),
          text: "堆垛架",
          width: "222px",
          height: "160px",
        },
        {
          url: require("@/assets/images/solution/details13/card4.png"),
          text: "保温箱",
          width: "222px",
          height: "160px",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2c2c2c;
  letter-spacing: 1px;
  margin: 40px 0;
}
.line {
  margin-top: 40px;
  width: 960px;
  height: 0px;
  border: 1px solid #dbdbdb;
}
.text {
  width: 960px;
  height: 60px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 24px;
}
.img2 {
  margin: 112px 0;
  width: 960px;
  height: 202px;
}
</style>