<!--
 * @Author: 小洋人
 * @Date: 2024-07-31 17:23:31
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-07-31 17:55:44
 * @FilePath: \kangmisi-officialwebsite\src\views\components\components\work.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div>
    <div class="list">
      <div v-for="(ele, i) in list" :key="i" class="list-list">
        <div class="ele">{{ ele.title }}</div>
        <div v-for="(item, index) in ele.list" :key="index" class="item">
          <div class="box" :style="{
            borderBottom: index == 2 ? 'none' : '1px solid #dbdbdb'
          }">
            <img :src="item.url" alt="" />
            <div class="img">
              <div class="title">{{ item.title }}</div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
}
.list-list {
  width: 472px;
  margin-right: 16px;
}
.ele {
  width: 472px;
  height: 80px;
  background: #e6e6e6;
  text-align: center;
  line-height: 80px;
  font-weight: bold;
  font-size: 18px;
  color: #2c2c2c;
}
.list-list:last-child {
  margin-right: 0px;
}
.box {
  margin: 0 24px;
  margin-right: 24px;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #dbdbdb;
  height: 136px;
}
// .box:last-child {
//   border-bottom: none;
// }
.item {
  width: 472px;
  background: #fafafa;

}
.img {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  margin-left: 24px;
}
.title {
  font-weight: bold;
  font-size: 18px;
  color: #2c2c2c;
}
img {
  width: 80px;
  height: 80px;
  margin: 28px 0px 16px 0;
}
.text {
  height: 37px;
  font-weight: 300;
  font-size: 14px;
  color: #2c2c2c;
  padding-top: 5px;
  width: 302px;
}
.item:nth-child(2n) {
  margin-right: 0px;
}
</style>