import { render, staticRenderFns } from "./details1002.vue?vue&type=template&id=689a1855&scoped=true"
import script from "./details1002.vue?vue&type=script&lang=js"
export * from "./details1002.vue?vue&type=script&lang=js"
import style0 from "./details1002.vue?vue&type=style&index=0&id=689a1855&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "689a1855",
  null
  
)

export default component.exports