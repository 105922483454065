<template>
  <div>
    <div class="list">
      <div v-for="(item, index) in cardList" :key="index" class="item">
        <img :src="item.url" alt="" />
        <div class="text">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cardList: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
}
.item {
  width: 176px;
  height: 160px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}
.item:nth-child(5n) {
  margin-right: 0px;
}
.text {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 176px;
  height: 37px;
  background: #2c2c2c;
  border-radius: 0px 0px 8px 8px;
  opacity: 0.8;
  text-align: center;
  line-height: 37px;
  color: #fff;
}
</style>