<template>
  <div>
    <div class="list" v-for="(item, index) in list" :key="index">
      <div v-if="item.type == 'left'" class="item">
        <img :src="item.url" alt="" />
        <div class="name">
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
      <div v-else class="item">
        <div class="name1">
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
        <img :src="item.url" alt="" class="img1" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  height: 280px;
  border-bottom: 1px solid #dbdbdb;
  width: 960px;
}
.list:last-child{
  border-bottom: none;
    
}
.item {
  display: flex;
  padding: 40px 0px;
  width: 960px;

  justify-content: space-between;
}
img {
  width: 228px;
  height: 200px;
  margin-left:40px;
}
.img1 {
  width: 228px;
  height: 200px;
  margin-right:40px;

}
.name {
  margin-left: 48px;
}
.title {
  font-weight: 400;
  font-size: 24px;
  color: #2c2c2c;
  margin-top: 58px;
}
.text {
//   width: 588px;
  font-weight: 300;
  font-size: 14px;
  color: #2c2c2c;
  margin-top: 16px;
}
.name1{
    text-align: right;
}
</style>