<template>
    <div class="pages">
      <div class="title">应用场景</div>
      <card :cardList="cardList"></card>
      <div class="line"></div>
      <div class="title">行业痛点</div>
      <wayPoint :list="pointList"></wayPoint>
      <div class="line"></div>
      <div class="title" style="margin-bottom: 24px">康米斯解决方案</div>
      <resolution :resolution-list="resolutionList"></resolution>
      <div class="line"></div>
      <div class="title">客户案例</div>
      <swiper :list="swiperList"></swiper>
      <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
    </div>
  </template>
  <script>
  import card from "./components/card.vue";
  import wayPoint from "./components/wayPoint.vue";
  import light from "./components/light.vue";
  import resolution from "./components/resolution.vue";
  import swiper from "./components/swiper.vue";
  export default {
    components: {
      wayPoint,
      card,
      light,
      resolution,
      swiper,
    },
    data() {
      return {
        pointList: [
          {
            url: require("@/assets/images/solution/details6/point1.png"),
            title: "位置轨迹获取难",
            type: "html",
            text: `无法限定出勤轨迹，超时不巡<br>逻无法监督`,
          },
          {
            url: require("@/assets/images/solution/details6/point2.png"),
            title: "费用统计难",
            type: "html",
            text: "每次出车，都要提交各种发<br>票，下载表格盖章提交，流程<br>复杂核对困难",
          },
          {
            url: require("@/assets/images/solution/details6/point3.png"),
            title: "安全管理难",
            type: "html",
            text: "巡逻出车乱开乱闯，不按规定<br>速度行驶",
          },
          {
            url: require("@/assets/images/solution/details6/point4.png"),
            title: "数据分析难",
            type: "html",
            text: "何时出车、何时归队、出车次<br>数记录混乱，事发点停留时<br>长、开车情况、轨迹情况全靠<br>人工记录",
          },
          {
            url: require("@/assets/images/solution/details6/point5.png"),
            title: "车辆维保控制难",
            type: "html",
            text: "车辆保养、年检、保险等记录<br>全靠人工记录，经常忘记。",
          },
          {
            url: require("@/assets/images/solution/details6/point6.png"),
            type: "html",
            title: "调度管理难",
            text: "接到出勤要求后，联系人员并<br>核对车辆位置，手忙脚乱。员<br>工用车时，跑来跑去走流程，<br>费时费力",
          }
        ],
        cardList: [
          {
            url: require("@/assets/images/solution/details6/card1.png"),
            text: "电力",
          },
          {
            url: require("@/assets/images/solution/details6/card2.png"),
            text: "水务",
          },
          {
            url: require("@/assets/images/solution/details6/card3.png"),
            text: "燃气",
          },
          {
            url: require("@/assets/images/solution/details6/card4.png"),
            text: "通信",
          },
          {
            url: require("@/assets/images/solution/details6/card5.png"),
            text: "警车",
          },
          {
            url: require("@/assets/images/solution/details6/card6.png"),
            text: "医疗急救车",
          },
          {
            url: require("@/assets/images/solution/details6/card7.png"),
            text: "消防车",
          },
          {
            url: require("@/assets/images/solution/details6/card8.png"),
            text: "抢险",
          },
        ],
        resolutionList: [
          {
            title: "车辆实时监控",
            text: "管理员查看出车车辆实时定位，全程可视化，根据案情位置和车辆位置研判更合适的方案。",
            url: require("@/assets/images/solution/details6/r0.png"),
            icon: require("@/assets/images/solution/details6/icon1.png"),
          },
          {
            title: "车辆智能调度",
            text: "管理员根据案情情况、车辆分布、人员排班情况调度，指派司机、车辆执行任务。",
            url: require("@/assets/images/solution/details6/r1.png"),
            icon: require("@/assets/images/solution/details6/icon2.png"),
          },
          {
            title: "路径监管",
            text: "历史回放可查看选择时间内车辆的历史轨迹以及车辆位置、车辆速度、里程等信息。通过指定线路情况提前绑定并设置区域路段限速值，如有违规偏离或者超速平台则会产生相应报警记录并语音提醒司机降速及路线偏离提醒。",
            url: require("@/assets/images/solution/details6/r2.png"),
            icon: require("@/assets/images/solution/details6/icon3.png"),
          },
          {
            title: "视频监控",
            text: "通过硬件设备、云端后台、大数据算法三大核心技术，为车辆提供实时定位追踪、安全视频监控、行车轨迹回溯、多重告警通知、司机驾驶行为汇总等数据存档及图表分析。",
            url: require("@/assets/images/solution/details6/r3.png"),
            icon: require("@/assets/images/solution/details6/icon4.png"),
          },
          {
            title: "车辆数据分析",
            text: "出车报表、行程报表、里程报表、熄火/打火报表、轨迹明细。自动生成报表、数据导出、多维度统计分析。",
            url: require("@/assets/images/solution/details6/r4.png"),
            icon: require("@/assets/images/solution/details6/icon5.png"),
          },
        ],
        swiperList: [
          {
            url: require("@/assets/images/solution/details6/s1.png"),
            title: "国家电网",
            html: `帮助国家电网<span style='color: #0F3391;font-weight: Bold;'>2600</span>多辆巡检车，解决了公车私用的问题，<span style='color: #0F3391;font-weight: Bold;'>3000+</span>巡检人员实现数字化管理，提高巡检工作效率。`,
          },
          {
            url: require("@/assets/images/solution/details6/s2.png"),
            title: "中国铁塔",
            html: `帮助中国铁塔<span style='color: #0F3391;font-weight: Bold;'>3000+<span>辆巡检车，解决了公车私用的问题。`,
          },
        ],
      };
    },
    mounted() {},
    methods: {},
  };
  </script>
  <style lang="less" scoped>
  .title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #2c2c2c;
    letter-spacing: 1px;
    margin: 40px 0;
  }
  .line {
    margin-top: 40px;
    width: 960px;
    height: 0px;
    border: 1px solid #dbdbdb;
  }
  .text {
    width: 960px;
    height: 60px;
    font-weight: 300;
    font-size: 16px;
    color: #2c2c2c;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .img2 {
    margin: 112px 0;
    width: 960px;
    height: 202px;
  }
  </style>
  