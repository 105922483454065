<template>
  <div class="pages">
    <div class="title">应用场景</div>
    <card-num :cardList="cardList"></card-num>
    <div class="line"></div>
    <div class="title" style="margin-bottom: 24px">方案架构</div>
    <div class="sub-title">贵重物品方案架构</div>
    <img
      src="@/assets/images/solution/details1003/img1.png"
      alt=""
      style="width: 960px; height: 462px"
    />

    <div class="sub-title">固定资产方案架构</div>
    <img
      src="@/assets/images/solution/details1003/img2.png"
      alt=""
      style="width: 960px; height: 462px"
    />
    <div class="sub-title">包装设备方案架构</div>
    <img
      src="@/assets/images/solution/details1003/img3.png"
      alt=""
      style="width: 960px; height: 462px"
    />
    <div class="sub-title">工程设备方案架构</div>
    <img
      src="@/assets/images/solution/details1003/img4.png"
      alt=""
      style="width: 960px; height: 462px"
    />
    <div class="line"></div>
    <div class="title">方案亮点</div>
    <light3 :list="lightList"></light3>
    <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
  </div>
</template>
<script>
import cardNum from "./components/card-num.vue";
import wayPoint from "./components/wayPoint.vue";
import light3 from "./components/light3.vue";
export default {
  components: {
    wayPoint,
    cardNum,
    light3,
  },
  data() {
    return {
      lightList: [
        {
          url: require("@/assets/images/solution/details1003/light1.png"),
          px: "104px",
          title: "北斗/GPS定位追踪",
          type: "html",
          textWidth: "300px",
          text: "双星实时定位追踪。<br>可查看设备实时分布情况，方便巡检。<br>可查看货物实时分布情况，保证运输时效。",
        },
        {
          url: require("@/assets/images/solution/details1003/light2.png"),
          title: "轨迹可视化",
          px: "104px",
          type: "html",
          textWidth: "300px",
          text: "运输路途监管。<br>运输运距长，过程复杂，容易造成订单失联，运输信息不透明。智能定位终端绑定货物，运输全程可视化，生成运输路途轨迹，可通过后台查看历史轨迹，保证运输时效，方便责任追溯。",
        },
        {
          url: require("@/assets/images/solution/details1003/light3.png"),
          title: "异常告警",
          px: "104px",
          type: "html",
          textWidth: "300px",
          text: "开箱开门报警。<br>车门安装门磁感应，系统记录开关门时间/地点，防止丢货串货；<br>智能终端配有光敏传感器，运输途中包装被异常打开平台提示开箱报警，帮助客户从源头至末端全程保障商品的安全可靠性。",
        },
        {
          px: "104px",
          url: require("@/assets/images/solution/details1003/light4.png"),
          title: "表单统计",
          type: "html",
          textWidth: "300px",
          text: "用户可选定时间段内运动总览、停留报表，告警报表，一键生成，为管理者提供有效的数据支持。",
        },
      ],
      cardList: [
        {
          url: require("@/assets/images/solution/details1003/card1.png"),
          text: "贵重物品",
          width: "222px",
          height: "160px",
        },
        {
          url: require("@/assets/images/solution/details1003/card2.png"),
          text: "固定资产",
          width: "222px",
          height: "160px",
        },
        {
          url: require("@/assets/images/solution/details1003/card3.png"),
          text: "包装设备",
          width: "222px",
          height: "160px",
        },
        {
          url: require("@/assets/images/solution/details1003/card4.png"),
          text: "工程设备",
          width: "222px",
          height: "160px",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2c2c2c;
  letter-spacing: 1px;
  margin: 40px 0;
}
.sub-title {
  font-weight: bold;
  font-size: 20px;
  color: #2c2c2c;
  margin-bottom: 16px;
  margin-top: 48px;
}
.line {
  margin-top: 40px;
  width: 960px;
  height: 0px;
  border: 1px solid #dbdbdb;
}
.text {
  width: 960px;
  height: 60px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 24px;
}
.img2 {
  margin: 112px 0;
  width: 960px;
  height: 202px;
}
</style>
