import { render, staticRenderFns } from "./light2.vue?vue&type=template&id=b38bb988&scoped=true"
import script from "./light2.vue?vue&type=script&lang=js"
export * from "./light2.vue?vue&type=script&lang=js"
import style0 from "./light2.vue?vue&type=style&index=0&id=b38bb988&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b38bb988",
  null
  
)

export default component.exports