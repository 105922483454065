<template>
  <div class="pages">
    <div class="title">应用场景</div>
    <card :cardList="cardList"></card>
    <div class="line"></div>
    <div class="title">行业痛点</div>
    <wayPoint :list="pointList"></wayPoint>
    <div class="line"></div>
    <div class="title">康米斯解决方案</div>

    <div class="fangan1">定位方案1：蓝牙信标</div>
    <div class="fang-list">
      <div class="item">
        <div class="item-title">方案简介</div>
        <div class="item-con">
          蓝牙信标的定位方案可按需实现<span>3~5m、5~8m、存在性检测</span>3种定位精度，同时结合自身:免综合布线、续航长、易维护、价格低廉等多项优势成为了应用最广的方案。
        </div>
      </div>
      <div class="item">
        <div class="item-title">方案链路</div>
        <div class="item-con">
          信标持续播发低频广播信号，标签接收数据后结合相对位置，输出原始位置信息至网关，网关汇总标签数据后通过有线或无线传至解算服务器进行最终数据呈现。
        </div>
      </div>
      <div class="item">
        <div class="item-title">方案优势</div>
        <div class="item-con">
          <div class="tem-con1">施工便捷</div>
          <div class="tem-con1">精度按需分配</div>
          <div class="tem-con1">标签样式多样化</div>
          <div class="tem-con2">成熟度高(90%以上的终端客户选择)</div>
        </div>
      </div>
    </div>
    <img
      src="@/assets/images/solution/details2/img7.png"
      alt=""
      class="img2"
    />
    <div class="hang" >
      <span>适合行业：</span>
      交通枢纽、医院养老、仓储物流、智慧楼宇、会展中心
    </div>
    <div class="line"></div>

    <div class="fang2">定位方案2：蓝牙AOA</div>
    <div class="fang2-list">
      <div
        v-for="(item, index) in fangList"
        :key="index"
        class="fang2-list-item"
      >
        <img :src="item.url" alt="" />
        <div class="fang2-name">{{ item.name }}</div>
        <div class="fang2-text1" v-if="item.text1">{{ item.text1 }}</div>
        <div v-else class="fang2-text2">
          <div class="time">续航时间:</div>
          <div>
            <div class="time1">标准模式:8天</div>
            <div class="time1">长续航模式:14天</div>
          </div>
        </div>
        <div class="fang2-text1">{{ item.text2 }}</div>
        <div class="fang2-text1">{{ item.text3 }}</div>
        <div class="fang2-text1" v-if="item.text4">{{ item.text4 }}</div>
        <div v-else class="fang2-text2">
          <div class="time">续航时间:</div>
          <div>
            <div class="time1">标准模式:8天</div>
            <div class="time1">长续航模式:14天</div>
          </div>
        </div>
      </div>
    </div>
    <div class="hang" style="margin-top: 8px">
      <span>适合行业：</span>
      智慧文旅、智慧商业、智慧停车、智慧园区、石油化工
    </div>
    <div class="line"></div>

    <div class="fangan3">定位方案3：UWB高精度定位</div>
    <div class="fang3-list">
      <div class="fang3-text">
        <div class="fang3-tiem">
          <span>带宽高：</span>UWB信号的带宽非常 宽，可以达到纳秒级，这使得它
          具有很高的时间分辨率。
        </div>
        <div class="fang3-tiem">
          <span>脉冲窄：</span>UWB信号的脉冲非常 窄，一般在几个纳秒以内，窄脉
          冲还具有更好的抗多径干扰能 力。
        </div>
        <div class="fang3-tiem">
          UWB的定位精度还可以通过高精 度UWB定位算法进一步优化，达
          到<span>厘米级别</span>的定位精度。
        </div>
      </div>
      <div class="img7">
        <img src="@/assets/images/solution/details1001/img7.png" alt="" />
      </div>
    </div>
    <div class="hang" style="margin-top: 6px; margin-bottom: 60px">
      <span>应用场景：</span>
      数字文旅、公检法司、智慧停车、交通枢纽、智慧医院、智慧工厂、仓储物流、电力能源、矿山隧道、智慧楼宇
    </div>
    <div class="line"></div>
    <div class="title">功能分工</div>
    <functionWrok :list='functionWrokList'></functionWrok>
    <img src="@/assets/images/solution/details1/img2.png" alt="" class="img3" />

  </div>
</template>
<script>
import card from "./components/card.vue";
import wayPoint from "./components/wayPoint.vue";
import functionWrok from "./components/function.vue";
export default {
  components: {
    wayPoint,
    card,
    functionWrok
  },
  data() {
    return {
      functionWrokList:[
        {
          url: require("@/assets/images/solution/details2/fun1.png"),
          title: "人员实时定位",
          text: "可对现场作业人员进行实时定位，准确获知员工、车辆实时位置，并支持查看其身份信息、位置轨迹等信息，解决业务挑战中员工实时位置难获知的诉求。",
          type:'left',
        },
         {
          url: require("@/assets/images/solution/details2/fun2.png"),
          title: "历史轨迹回溯",
          text: "历史轨迹功能支持对过往的标签位置数据进行回溯，便于复核外来访客及承包商在厂区的活动范围及触发的告警，并进行有效管控。有助于解决业务挑战中历史轨迹难追溯的问题，并为事件后的责任追究提供技术依据。",
          type:'right',
        },
         {
          url: require("@/assets/images/solution/details2/fun3.png"),
          title: "视频联动",
          text: "视频联动功能主要是为提高工作场所的安全性和管理效率所设计的，当管理者想获知现场人员作业巡检过程时，可使用视频联动功能，人员携带标签进入视频监控范围时，可自动弹出视频画面，管理者可结合标签实时位置和视频图像判断现场作业的安全性和规范性，同时当人员使用SOS告警时，也可通过视频联动功能，提前查看员工周围的环境，为现场救援作出预案。",
          type:'left',
        },
         {
          url: require("@/assets/images/solution/details2/fun4.png"),
          title: "智能巡检",
          text: "智能巡检功能可基于基础位置数据及巡检业务逻辑，对企业传统的巡检工作进行辅助智能巡检功能，基于位置地图，可灵活设置巡检点、巡检时间、巡检完成条件，并制定巡检路线，最终将任务分配给指定的人员/班组， 防止不按时、不按点巡检、代检、漏检等隐患。",
          type:'right',
        },
        {
          url: require("@/assets/images/solution/details2/fun5.png"),
          title: "电子围栏",
          text: "通过在管控区域设定电子围栏，当无权限人员进入或离开电子围栏区域时，即触发告警，此时系统会自动向管理人员发出告警，并提供详细的位置信息。通过这种方式，可以及时发现和处理潜在的安全隐患和突发事件，保护员工的安全。",
          type:'left',
        },
        {
          url: require("@/assets/images/solution/details2/fun6.png"),
          title: "告警管理",
          text: "基于企业的业务应用，还可设置多种逻辑的电子围栏，以此来规范人员在厂区的活动范围、作业状态，防止危险事件的发生。",
          type:'right',
        },
         {
          url: require("@/assets/images/solution/details2/fun7.png"),
          title: "人员聚集告警",
          text: "为避免人员聚集产生的安全隐患，可通过人员聚集功能进行实时监管，超过限定的人数，系统将实时产生告警，并将告警推送至相关责任人进行受理，避免了以往聚集形成也无从得知无从预警的情况发生。",
          type:'left',
        },
         {
          url: require("@/assets/images/solution/details2/fun8.png"),
          title: "SOS一键告警",
          text: "当人员在化工厂遇险时，员工可按下定位标签上的SOS按钮向后端监控中心提出救援请求，告警将实时传输至后端，监控平台做声光告警提醒，提醒监控中心监管人员施救，SOS告警为紧急事件发生时提供了主动上报求救的手段。",
          type:'right',
        },
      ],
      pointList: [
        {
          url: require("@/assets/images/solution/details2/point1.png"),
          title: "人员调度效率低",
          text: "室内人员管理流程不规范，信息传递不及时、‌不准确，导致工作延误、‌形成资源浪费。",
        },
        {
          url: require("@/assets/images/solution/details2/point2.png"),
          title: "人员轨迹难追踪",
          text: "对有位置需求的室内人员，难以做到实时了解对方的位置、行动轨迹，以便更好地排班调度。",
        },
        {
          url: require("@/assets/images/solution/details2/point3.png"),
          title: "作业安全难保证",
          text: "针对危化园区，矿山隧道等危险作业区，如发生火灾、爆炸、有害物质泄露或作业操作不当等情况，很可能危及员工的生命安全。",
        },
        {
          url: require("@/assets/images/solution/details2/point4.png"),
          title: "遇险救援不及时",
          text: "在事故发生后，无法获取员工的准确位置，就无法快速出具紧急救险方案，耽误救险时间，造成人员伤亡。",
        },
      ],
      cardList: [
        {
          url: require("@/assets/images/solution/details2/card1.png"),
          text: "智慧工厂",
        },
        {
          url: require("@/assets/images/solution/details2/card2.png"),
          text: "矿山矿井",
        },
        {
          url: require("@/assets/images/solution/details2/card3.png"),
          text: "隧道施工",
        },
        {
          url: require("@/assets/images/solution/details2/card4.png"),
          text: "交通枢纽",
        },
        {
          url: require("@/assets/images/solution/details2/card5.png"),
          text: "能源基建",
        },
        {
          url: require("@/assets/images/solution/details2/card6.png"),
          text: "仓储物流",
        },
        {
          url: require("@/assets/images/solution/details2/card7.png"),
          text: "数字文旅",
        },
        {
          url: require("@/assets/images/solution/details2/card8.png"),
          text: "智慧医院",
        },
        {
          url: require("@/assets/images/solution/details2/card9.png"),
          text: "智慧楼宇",
        },
        {
          url: require("@/assets/images/solution/details2/card10.png"),
          text: "公检司法",
        },
        {
          url: require("@/assets/images/solution/details2/card11.png"),
          text: "石油化工",
        },
        {
          url: require("@/assets/images/solution/details2/card12.png"),
          text: "智慧停车",
        },
      ],
      fangList: [
        {
          url: require("@/assets/images/solution/details1001/img3.png"),
          name: "手环型标签",
          text1: "",
          text2: "防护等级: IP67",
          text3: "充电接口: 磁吸",
          text4: "",
        },
        {
          url: require("@/assets/images/solution/details1001/img4.png"),
          name: "蓝牙定位标签",
          text1: "电池种类: 一次性电池",
          text2: "工作温度: 一次性电池",
          text3: "防护等级: IP68",
          text4: "供电续航: 内置电池，续航3~5年",
        },
        {
          url: require("@/assets/images/solution/details1001/img5.png"),
          name: "工牌标签",
          text1: "续航时间: 10~15天",
          text2: "防护等级: IP67",
          text3: "充电接口: Type-c",
          text4: "支持SOS一键报警",
        },
        {
          url: require("@/assets/images/solution/details1001/img6.png"),
          name: "AOA 定位基站",
          text1: "数据接口: 标准以太网",
          text2: "电源接口: POE",
          text3: "定位精度: 1m",
          text4: "工作温度: -20-70℃",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.pages {
  //   background-color: pink;
}
.title {
  font-size: 24px;
  color: #2c2c2c;
  margin-top: 56px;
  margin-bottom: 16px;
}
.desc {
  font-weight: 300;
  font-size: 14px;
  color: #2c2c2c;
  line-height: 20px;
  padding-bottom: 24px;
  border-bottom: 1px solid #dbdbdb;
}
.title1 {
  font-weight: 400;
  font-size: 18px;
  color: #2c2c2c;
  margin-bottom: 16px;
  margin-top: 32px;
}
.img1 {
  width: 960px;
  height: 420px;
  margin-bottom: 24px;
}
.fangan1 {
  height: 21px;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 16px;
}
.fang-list {
  display: flex;
  justify-content: space-around;
}
.item {
  width: 316px;
  .item-title {
    height: 64px;
    background: #8197b5;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 64px;
    text-align: center;
  }
  .item-con {
    font-weight: 300;
    font-size: 14px;
    color: #2c2c2c;
    line-height: 32px;
    width: 316px;
    height: 166px;
    padding: 16px;
    background: #f8f8f8;
    box-sizing: border-box;
    margin-top: 6px;

    span {
      font-weight: bold;
      font-size: 14px;
      color: #0f3391;
    }
  }
  .tem-con2 {
    font-weight: bold;
    font-size: 14px;
    color: #0f3391;
  }
}
.img2 {
  width: 960px;
  height: 347px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.hang {
  width: 960px;
  height: 64px;
  background: #f8f8f8;
  line-height: 64px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  box-sizing: border-box;
  padding-left: 24px;
  span {
    font-weight: bold;
    font-size: 16px;
    color: #2c2c2c;
  }
}
.fang2 {
  margin-top: 32px;
  font-size: 16px;
  color: #2c2c2c;
  margin-bottom: 16px;
}
.fang2-list {
  display: flex;
  justify-content: space-around;
  .fang2-list-item {
    width: 234px;
    img {
      width: 234px;
      height: 220px;
      border: 1px dashed #b2b2b2;
      background-color: #fafafa;
    }
    .fang2-name {
      width: 236px;
      height: 56px;
      background: #8197b5;
      padding-left: 16px;
      line-height: 56px;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      margin-top: 8px;
      box-sizing: border-box;
    }
    .fang2-text1 {
      width: 236px;
      height: 48px;
      background: #f8f8f8;
      font-size: 14px;
      color: #2c2c2c;
      line-height: 48px;
      padding-left: 16px;
      margin-top: 8px;
      box-sizing: border-box;
    }
    .fang2-text2 {
      width: 236px;
      background: #f8f8f8;
      margin-top: 8px;
      box-sizing: border-box;
      height: 48px;
      display: flex;
      padding-top: 4px;
      .time {
        font-size: 14px;
        color: #2c2c2c;
        padding-left: 16px;
      }
      .time1 {
        font-size: 14px;
        color: #2c2c2c;
        padding-left: 8px;
      }
    }
  }
}
.fangan3 {
  margin-top: 32px;
  margin-bottom: 16px;
}
.fang3-list {
  display: flex;
  justify-content: space-between;
  .fang3-text {
    width: 264px;
    height: 448px;
    background: #f8f8f8;
    padding: 16px 32px;
    box-sizing: border-box;
  }
  .fang3-tiem {
    width: 196px;
    font-weight: 300;
    font-size: 14px;
    color: #2c2c2c;
    line-height: 24px;
    margin-top: 48px;
    span {
      font-weight: 700;
    }
  }
}
.img7 {
  width: 690px;
  height: 448px;
  img {
    width: 690px;
    height: 448px;
  }
}

.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2c2c2c;
  letter-spacing: 1px;
  margin: 40px 0;
}
.line {
  margin-top: 40px;
  width: 960px;
  height: 0px;
  border: 1px solid #dbdbdb;
}
.img3 {
  margin: 112px 0;
  width: 960px;
  height: 202px;
}
</style>