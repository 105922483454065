<template>
  <div class="pages">
    <div class="title">应用场景</div>
    <card-num :cardList="cardList"></card-num>
    <div class="line"></div>
    <div class="title">行业痛点</div>
    <wayPoint :list="pointList"></wayPoint>
    <div class="line"></div>
    <div class="title" style="margin-bottom: 24px">康米斯解决方案</div>
    <resolution :resolution-list="resolutionList"></resolution>
    <div class="line"></div>
    <light :list="lightList" textWidth="288px"></light>
    <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
  </div>
</template>
<script>
//   import card from "./components/card.vue";
import cardNum from "./components/card-num.vue";
import wayPoint from "./components/wayPoint.vue";
import light from "./components/light.vue";
import resolution from "./components/resolution.vue";
import swiper from "./components/swiper.vue";
export default {
  components: {
    wayPoint,
    cardNum,
    light,
    resolution,
    swiper,
  },
  data() {
    return {
      pointList: [
        {
          url: require("@/assets/images/solution/details7/point1.png"),
          title: "定位监控难",
          type: "html",
          text: `车辆位置难知道，车辆资产难<br>管理。`,
        },
        {
          url: require("@/assets/images/solution/details7/point2.png"),
          title: "安全管理难",
          type: "html",
          text: "驾驶员不良、危险驾驶行为，<br>无法监管车辆的情况。",
        },
        {
          url: require("@/assets/images/solution/details7/point3.png"),
          title: "效益增长难",
          type: "html",
          text: "人工管理流程繁琐，单车效益<br>低，经济效益难增长。",
        },
        {
          url: require("@/assets/images/solution/details7/point4.png"),
          title: "数据统计难",
          type: "html",
          text: "订单里程、油费、ETC等费用<br>可操作方式多，还车验车、对<br>账难。",
        },
      ],
      cardList: [
        {
          url: require("@/assets/images/solution/details7/card1.png"),
          text: "网约车",
          width: "468px",
          height: "252px",
        },
        {
          url: require("@/assets/images/solution/details7/card2.png"),
          text: "租赁车",
          width: "468px",
          height: "252px",
        },
      ],
      resolutionList: [
        {
          title: "智能调度",
          text: "调度员可一键调度。根据车辆维保、限号、任务中的情况，防调度冲突。实现多人协同管理、移动调度、顾客移动端下单。",
          url: require("@/assets/images/solution/details7/r0.png"),
          icon: require("@/assets/images/solution/details7/icon1.png"),
        },
        {
          title: "车辆监管",
          text: "管理员通过车队管家后台实时监控车辆状态，确保人车安全。实时位置监控，里程、时速统计，超速/疲劳驾驶报警，轨迹回放，电子围栏报警，行程明细查看，前方车道、司机驾驶行为、等视频监控，安全预警。",
          url: require("@/assets/images/solution/details7/r1.png"),
          icon: require("@/assets/images/solution/details7/icon2.png"),
        },
        {
          title: "视频监控",
          text: "通过硬件设备、云端后台、大数据算法三大核心技术，为车辆提供实时定位追踪、安全视频监控、行车轨迹回溯、多重告警通知、司机驾驶行为汇总等数据存档及图表分析。",
          url: require("@/assets/images/solution/details7/r2.png"),
          icon: require("@/assets/images/solution/details7/icon3.png"),
        },
        {
          title: "油耗检测",
          text: "定位器+油耗上报，监控车辆油耗，自动生成报表。",
          url: require("@/assets/images/solution/details7/r3.png"),
          icon: require("@/assets/images/solution/details7/icon4.png"),
        },
        {
          title: "财务结算",
          text: "管理员利用财务报表进行费用结算，车辆全成本管理。费用录入、上传/核对凭证，自动生成报表，系统自动同步。",
          url: require("@/assets/images/solution/details7/r4.png"),
          icon: require("@/assets/images/solution/details7/icon5.png"),
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2c2c2c;
  letter-spacing: 1px;
  margin: 40px 0;
}
.line {
  margin-top: 40px;
  width: 960px;
  height: 0px;
  border: 1px solid #dbdbdb;
}
.text {
  width: 960px;
  height: 60px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 24px;
}
.img2 {
  margin: 112px 0;
  width: 960px;
  height: 202px;
}
</style>
