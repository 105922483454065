<!--
 * @Author: 小洋人
 * @Date: 2024-08-07 14:59:22
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-08-07 15:10:08
 * @FilePath: \kangmisi-officialwebsite\src\views\components\details12.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div class="pages">
    <div class="title">应用场景</div>
    <card :cardList="cardList"></card>
    <div class="line"></div>
    <div class="title">行业痛点</div>
    <wayPoint :list="pointList"></wayPoint>
    <div class="line"></div>
    <div class="title">方案架构</div>
    <img
      src="@/assets/images/solution/details12/img3.png"
      alt=""
      style="width: 960px; height: 388px"
    />
    <div class="line"></div>
    <div class="title">康米斯解决方案</div>
    <fang :list="fangList"></fang>
    <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
  </div>
</template>
<script>
import card from "./components/card.vue";
import wayPoint from "./components/wayPoint.vue";
import fang from "./components/fang.vue";
export default {
  components: {
    wayPoint,
    card,
    fang,
  },
  data() {
    return {
      fangList: [
        {
          url: require("@/assets/images/solution/details12/fang1.png"),
          title: "位置监管",
          text: "实时定位追踪功能配置GPS/北斗定位设备，可查看资产实时分布情况，方便管理。",
        },
        {
          url: require("@/assets/images/solution/details12/fang2.png"),
          title: "围栏监管",
          text: "通过定位和围栏功能，管理者可实现资产全程把控， 及时获知其动向，也可进行远程盘点或调度管理。",
        },
        {
          url: require("@/assets/images/solution/details12/fang3.png"),
          title: "户外资产监控",
          text: "4G太阳能定位器，同时支持太阳能与磁吸两种充电方式，并具有IP67防尘防水设计，适用于户外资产。终端融合了4G通信与卫星定位技术，支持智能定位、定时定位、超长待机定位模式及拆卸报警、声光报警、蓝牙外扩等功能。",
        },
        {
          url: require("@/assets/images/solution/details12/fang4.png"),
          title: "快速保修",
          text: "可以为客户提供问题预警、报修提醒等服务，确保资产正常运行。",
        },
      ],
      pointList: [
        {
          url: require("@/assets/images/solution/details12/point1.png"),
          title: "固资管理流程乱",
          text: "传统纸笔记录留存，效率低，更新难且易出错",
        },
        {
          url: require("@/assets/images/solution/details12/point2.png"),
          title: "问题责任追溯难",
          text: "当出现设备丢失、损坏、偷换等意外情况时，难以迅速定位责任方",
        },
        {
          url: require("@/assets/images/solution/details12/point3.png"),
          title: "资产盘点效率低",
          text: "盘点工作量大，传统模式速度慢且易出现错盘漏盘现象",
        },
        {
          url: require("@/assets/images/solution/details12/point4.png"),
          title: "资产状态追溯难",
          text: "传统管理方式下，资产状态变更不及时，容易遗漏出错，异地盘点成本高",
        },
        {
          url: require("@/assets/images/solution/details12/point5.png"),
          title: "运营成本降低难",
          text: "盘点过程中产生不必要开支，企业无法降低运营成本，提高资源利用率",
        },
      ],
      cardList: [
        {
          url: require("@/assets/images/solution/details12/card1.png"),
          text: "大型医疗设备",
        },
        {
          url: require("@/assets/images/solution/details12/card2.png"),
          text: "冷冻柜",
        },
        {
          url: require("@/assets/images/solution/details12/card3.png"),
          text: "户外广告牌",
        },
        {
          url: require("@/assets/images/solution/details12/card4.png"),
          text: "基站供电箱",
        },
        {
          url: require("@/assets/images/solution/details12/card5.png"),
          text: "精密仪器",
        },
        {
          url: require("@/assets/images/solution/details12/card6.png"),
          text: "大型能源生产设备",
        },
        {
          url: require("@/assets/images/solution/details12/card7.png"),
          text: "公用事业井盖",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2c2c2c;
  letter-spacing: 1px;
  margin: 40px 0;
}
.line {
  margin-top: 40px;
  width: 960px;
  height: 0px;
  border: 1px solid #dbdbdb;
}
.text {
  width: 960px;
  height: 60px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 24px;
}
.img2 {
  margin: 112px 0;
  width: 960px;
  height: 202px;
}
</style>