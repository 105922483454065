
<template>
  <div class="pages">
    <div class="title">应用场景</div>
    <card :cardList="cardList"></card>
    <div class="line"></div>
    <div class="title">行业痛点</div>
    <wayPoint :list="pointList"></wayPoint>
    <div class="line"></div>
    <div class="title" style="margin-bottom: 24px">康米斯解决方案</div>
    <resolution :resolution-list="resolutionList"></resolution>
    <div class="line"></div>
    <div class="title">方案亮点</div>
    <div class="line"></div>
    <light :list="lightList" textWidth="288px"></light>
    <div class="title">客户案例</div>
    <swiper :list="swiperList"></swiper>
    <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
  </div>
</template>
<script>
import card from "./components/card.vue";
import wayPoint from "./components/wayPoint.vue";
import light from "./components/light.vue";
import resolution from "./components/resolution.vue";
import swiper from "./components/swiper.vue";
export default {
  components: {
    wayPoint,
    card,
    light,
    resolution,
    swiper,
  },
  data() {
    return {
      lightList: [
        {
          url: require("@/assets/images/solution/details5/light1.png"),
          px: "104px",
          title: "提升信息化管理水平",
          text: "车辆信息资源整合，管理流程信息化。",
        },
        {
          url: require("@/assets/images/solution/details5/light2.png"),
          title: "提高驾驶安全性",
          px: "104px",
          text: "线路可规划、驾驶行为可分析，可监管。",
        },
        {
          url: require("@/assets/images/solution/details5/light3.png"),
          title: "降低运营成本",
          px: "104px",
          text: "减少车辆行驶中不合理开支。",
        },
        {
          px: "104px",
          url: require("@/assets/images/solution/details5/light4.png"),
          title: "提升运输服务效率",
          text: "提升司机、企业员工的工作效率。",
        },
        {
          px: "104px",
          url: require("@/assets/images/solution/details5/light4.png"),
          title: "保证数据真实性",
          text: "数据真实可靠，传递及时、有效遏制相关人员监守自盗。",
        },
      ],
      pointList: [
        {
          url: require("@/assets/images/solution/details5/point1.png"),
          title: "新客新市场开拓难",
          type: "html",
          text: `核心管理质量低，对外运营策<br>略无法有效协同`,
        },
        {
          url: require("@/assets/images/solution/details5/point2.png"),
          title: "位置轨迹获取难",
          type: "html",
          text: "行程不透明，企业和客户都<br>无法知道运输情况<br>",
        },
        {
          url: require("@/assets/images/solution/details5/point3.png"),
          title: "货物途中监管难",
          type: "html",
          text: "运输中发生货损无法明确事<br>故责任",
        },
        {
          url: require("@/assets/images/solution/details5/point4.png"),
          title: "行车安全保障难",
          type: "html",
          text: "司机行为不可控，增加货运过<br>程中安全隐惠",
        },
        {
          url: require("@/assets/images/solution/details5/point5.png"),
          title: "承运车辆调度难",
          type: "html",
          text: "调度不合理造成用车紧张或资<br>源浪费",
        },
        {
          url: require("@/assets/images/solution/details5/point6.png"),
          type: "html",
          title: "车辆维保控制难",
          text: "统计跟进依靠人工，容易遗漏<br>导致车保和使用造成冲突",
        },
        {
          url: require("@/assets/images/solution/details5/point7.png"),
          type: "html",
          title: "物流车队管理难",
          text: "物流企业不能及时、准确、全<br>面地掌控车辆信息，管理方案<br>不能及时调整优化",
        },
        {
          url: require("@/assets/images/solution/details5/point8.png"),
          type: "html",
          title: "运营成本降低难",
          text: "大量环节产生不必要开支，运<br>营成本居高不下",
        },
      ],
      cardList: [
        {
          url: require("@/assets/images/solution/details5/card1.png"),
          text: "冷链",
        },
        {
          url: require("@/assets/images/solution/details5/card2.png"),
          text: "商超配送",
        },
        {
          url: require("@/assets/images/solution/details5/card3.png"),
          text: "大宗商品",
        },
        {
          url: require("@/assets/images/solution/details5/card4.png"),
          text: "货运平台",
        },
        {
          url: require("@/assets/images/solution/details5/card5.png"),
          text: "物流公司",
        },
      ],
      resolutionList: [
        {
          title: "车辆实时定位",
          text: "系统可对车辆、人员实时监控，如车辆实况，车辆行程，车辆位置，<br>车辆违规报警等，能看到车辆所有状态及运输情况。",
          url: require("@/assets/images/solution/details5/r0.png"),
          icon: require("@/assets/images/solution/details5/icon1.png"),
        },
        {
          title: "车务管理",
          text: "信息管理、保养提醒、年检提醒、保险提醒。<br>全方位车务信息管理、周期性短信/APP自动提醒，其他提醒设置、<br>维保管理、事故管理、费用管理。",
          url: require("@/assets/images/solution/details5/r1.png"),
          icon: require("@/assets/images/solution/details5/icon2.png"),
        },
        {
          title: "实时路线监管",
          text: "历史回放可查看选择时间内车辆的历史轨迹以及车辆位置、车辆速<br>度、里程等信息。",
          url: require("@/assets/images/solution/details5/r2.png"),
          icon: require("@/assets/images/solution/details5/icon3.png"),
        },
        {
          title: "视频监控",
          text: "通过车载视频摄像头，管理员可以远程查看车外、车内驾驶人员、货<br>仓内情况，做到真正的全程可视化。",
          url: require("@/assets/images/solution/details5/r3.png"),
          icon: require("@/assets/images/solution/details5/icon4.png"),
        },
        {
          title: "ADAS/DSM/BSD安全管理",
          text: "通过车载终端、视频产品，对车辆及司机的安全情况进行实时监管。<br>车道偏移/频发变道/实线变道/盲区/疲劳/分神/打瞌睡/接电话/玩手<br>机/抽烟。",
          url: require("@/assets/images/solution/details5/r4.png"),
          icon: require("@/assets/images/solution/details5/icon5.png"),
        },
        {
          title: "报表统计",
          text: "排班考勤报表、里程统计、行程报表、司机考核。自动生成报表、数<br>据导出、多维度统计分析。",
          url: require("@/assets/images/solution/details5/r5.png"),
          icon: require("@/assets/images/solution/details5/icon6.png"),
        },
      ],
      swiperList: [
        {
          url: require("@/assets/images/solution/details5/s1.png"),
          title: "运满满",
          html: `对<span style='color: #0F3391;font-weight: Bold;'>50w+</span>货物运输挂靠车辆进行了统一的管理，帮助企业解决了挂靠货运约束难的痛点。`,
        },
        {
          url: require("@/assets/images/solution/details5/s2.png"),
          title: "中储智运",
          html: `通过LOT智能设备，不间断上传车辆和船舶的位置信息，极快地帮助企业实现管理货物运输过程中的人员管理和位置管理。`,
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2c2c2c;
  letter-spacing: 1px;
  margin: 40px 0;
}
.line {
  margin-top: 40px;
  width: 960px;
  height: 0px;
  border: 1px solid #dbdbdb;
}
.text {
  width: 960px;
  height: 60px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 24px;
}
.img2 {
  margin: 112px 0;
  width: 960px;
  height: 202px;
}
</style>
