var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",attrs:{"id":"page"}},[_c('heade',{attrs:{"active":2},on:{"change":_vm.changeTop}}),_c('div',{staticStyle:{"height":"64px"}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"menu"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"list",on:{"click":function($event){return _vm.changeFaType(item.id)}}},[_c('div',{staticClass:"list-l"},[(_vm.activeTypeIndex == item.id)?_c('div',{staticClass:"line-l"}):_vm._e(),_c('div',{staticClass:"title",style:({
                color: _vm.activeTypeIndex == item.id ? '#0f3391' : '#2c2c2c',
                fontWeight: _vm.activeTypeIndex == item.id ? '700' : '400',
                paddingLeft: _vm.activeTypeIndex == item.id ? '0px' : '8px',
              }),on:{"click":function($event){return _vm.changeType(item.id)}}},[_vm._v(" "+_vm._s(item.title)+" ")])]),_vm._l((item.list),function(ele,i){return _c('div',{key:i,staticClass:"list-item",on:{"click":function($event){$event.stopPropagation();return _vm.changeInfo(ele.id, index, i)}}},[(_vm.activeIndex == ele.id)?_c('div',{staticClass:"line"}):_vm._e(),_c('div',{staticClass:"name",style:({
                color: _vm.activeIndex == ele.id ? '#0f3391' : '#2c2c2c',
                fontWeight: _vm.activeIndex == ele.id ? '700' : '400',
              })},[_vm._v(" "+_vm._s(ele.name)+" ")])])}),(index != _vm.list.length - 1)?_c('div',{staticClass:"line"}):_vm._e()],2)}),0),_c('div',{staticClass:"details"},[_c(_vm.currentComponent,{tag:"component"})],1)])]),[_c('bottom')]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }