<template>
  <div class="box">
    <div class="box-item" v-for="(item, index) in resolutionList" :key="index">
      <img :src="item.url" />
      <div class="box-item-text">
        <div class="title">
          <img :src="item.icon" />
          {{ item.title }}
        </div>
        <div class="text" v-html="item.text"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    resolutionList: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.box {
  display: flex;
  flex-wrap: wrap;
  &-item {
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.16);
    width: 468px;
    height: 415px;
    margin-bottom: 32px;
    background: #ffffff;

    &-text {
      box-sizing: border-box;
      padding:16px 24px;
      .title {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        color: #2c2c2c;
        line-height: 0px;
        margin-bottom: 8px;

        img {
          margin-right: 4px;
        }
      }
      .text {
        font-weight: 300;
        font-size: 14px;
        color: #2c2c2c;
        line-height: 20px;
        text-align: left;
      }
    }
  }
  img {
    margin: 0;
  }
}
.box > :nth-child(odd) {
  margin-right: 24px;
}
</style>
