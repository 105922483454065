<!--
 * @Author: 小洋人
 * @Date: 2024-08-02 19:08:28
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-08-02 19:45:48
 * @FilePath: \kangmisi-officialwebsite\src\views\components\details1002.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div class="pages">
    <div class="title">应用场景</div>
    <card :cardList="cardList"></card>
    <div class="line"></div>
    <div class="title">行业痛点</div>
    <wayPoint :list="pointList"></wayPoint>
    <div class="line"></div>
    <div class="title" style="margin-bottom: 24px">康米斯方案架构</div>
    <img
      src="@/assets/images/solution/details1002/img1.png"
      alt=""
      style="width: 960px; height: 819px"
    />
    <div class="line"></div>
    <div class="title">方案亮点</div>
    <light :list="lightList"></light>
    <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
  </div>
</template>
<script>
import card from "./components/card.vue";
import wayPoint from "./components/wayPoint.vue";
import light from "./components/light.vue";
export default {
  components: {
    wayPoint,
    card,
    light,
  },
  data() {
    return {
      lightList: [
        {
          url: require("@/assets/images/solution/details1002/light1.png"),
          px: "80px",
          title: "提升管理效率",
          text: "通过平台实时展现的车辆信息，管理者可随时了解车辆位置和车辆使用情况，方便灵活调度车辆。多车统一管理+信息化管理+数据化考核，降低管理的时间成本。",
        },
        {
          url: require("@/assets/images/solution/details1002/light2.png"),
          title: "减少运营成本",
          px: "80px",
          text: "通过位置+围栏监控，可以防止公车私用，信息化的里程和油耗统计，也能避免私油公报。利用综合统计报表，管理者也可以科学进行司机和车辆配置，减少资源浪费。",
        },
        {
          url: require("@/assets/images/solution/details1002/light3.png"),
          title: "加强安全监控",
          px: "80px",
          text: "通过终端可监测到司机是否存在不良驾驶行为(急加速、急转弯、超速等)，并给出驾驶评分，可以作为考核标准，以规范司机驾驶习惯，减少交通事故和罚单。",
        },
        {
          px: "80px",
          url: require("@/assets/images/solution/details1002/light4.png"),
          title: "提升公众形象",
          text: "车辆、司机管理信息化、透明化、规范化，可避免公车私用等腐败现象，从而减少行政支出，并使监管问责更科学有效,有利于建立廉洁型事业单位。",
        },
      ],
      pointList: [
        {
          url: require("@/assets/images/solution/details1002/point1.png"),
          title: "车辆监管难",
          type: "html",
          text: `时长里程难统计<br>车辆使用情况不清<br>公车私用难管控`,
        },
        {
          url: require("@/assets/images/solution/details1002/point2.png"),
          title: "车务管理难",
          type: "html",
          text: "档案管理繁杂<br>年检保险保养到期无提醒<br>维保、事故管理难",
        },
        {
          url: require("@/assets/images/solution/details1002/point3.png"),
          title: "用车流程繁琐",
          type: "html",
          text: "用车流程繁琐且不规范<br>无法进行用车评价<br>用车体验不佳",
        },
        {
          url: require("@/assets/images/solution/details1002/point4.png"),
          title: "费用管理难",
          type: "html",
          text: "油费、ETC费居高不下<br>虚报多报难核实<br>统计分析难",
        },
        {
          url: require("@/assets/images/solution/details1002/point5.png"),
          title: "监管驾驶行为难",
          type: "html",
          text: "司机不安全驾驶行为多<br>无法及时预警和提醒车辆<br>事故率偏高",
        },
        {
          url: require("@/assets/images/solution/details1002/point6.png"),
          type: "html",
          title: "合理调度难",
          text: "车辆使用率低<br>易错单漏单<br>效率低下",
        },
      ],
      cardList: [
        {
          url: require("@/assets/images/solution/details1002/card1.png"),
          text: "汽车金融风控",
        },
        {
          url: require("@/assets/images/solution/details1002/card2.png"),
          text: "外勤作业车辆",
        },
        {
          url: require("@/assets/images/solution/details1002/card3.png"),
          text: "货运物流车辆",
        },
        {
          url: require("@/assets/images/solution/details1002/card4.png"),
          text: "应急保障车辆",
        },
        {
          url: require("@/assets/images/solution/details1002/card5.png"),
          text: "网约车",
        },
        {
          url: require("@/assets/images/solution/details1002/card6.png"),
          text: "租赁车",
        },
        {
          url: require("@/assets/images/solution/details1002/card7.png"),
          text: "政企公车",
        },
        {
          url: require("@/assets/images/solution/details1002/card8.png"),
          text: "工程车辆",
        },
        {
          url: require("@/assets/images/solution/details1002/card9.png"),
          text: "特种车辆",
        },
        {
          url: require("@/assets/images/solution/details1002/card10.png"),
          text: "冷链车",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2c2c2c;
  letter-spacing: 1px;
  margin: 40px 0;
}
.line {
  margin-top: 40px;
  width: 960px;
  height: 0px;
  border: 1px solid #dbdbdb;
}
.text {
  width: 960px;
  height: 60px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 24px;
}
.img2 {
  margin: 112px 0;
  width: 960px;
  height: 202px;
}
</style>