<template>
  <div class="page" id="page">
    <heade :active="2" @change="changeTop"></heade>
    <div style="height: 64px"></div>
    <div class="content">
      <div class="main">
        <!-- 左边菜单 -->
        <div class="menu">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="list"
            @click="changeFaType(item.id)"
          >
            <div class="list-l">
              <div class="line-l" v-if="activeTypeIndex == item.id"></div>
              <div
                class="title"
                :style="{
                  color: activeTypeIndex == item.id ? '#0f3391' : '#2c2c2c',
                  fontWeight: activeTypeIndex == item.id ? '700' : '400',
                  paddingLeft: activeTypeIndex == item.id ? '0px' : '8px',
                }"
                @click="changeType(item.id)"
              >
                {{ item.title }}
              </div>
            </div>
            <div
              v-for="(ele, i) in item.list"
              :key="i"
              class="list-item"
              @click.stop="changeInfo(ele.id, index, i)"
            >
              <div class="line" v-if="activeIndex == ele.id"></div>
              <div
                class="name"
                :style="{
                  color: activeIndex == ele.id ? '#0f3391' : '#2c2c2c',
                  fontWeight: activeIndex == ele.id ? '700' : '400',
                }"
              >
                {{ ele.name }}
              </div>
            </div>
            <div class="line" v-if="index != list.length - 1"></div>
          </div>
        </div>
        <!-- 右边详情 -->
        <div class="details">
          <component :is="currentComponent"></component>
        </div>
      </div>
    </div>
    <!-- 首页 -->
    <template>
      <bottom></bottom>
    </template>
  </div>
</template>

<script>
import bottom from "./bottom.vue";
import heade from "./header.vue";
import details1001 from "./components/details1001.vue";
import details1002 from "./components/details1002.vue";
import details1003 from "./components/details1003.vue";
import details1 from "./components/details1.vue";
import details2 from "./components/details2.vue";
import details3 from "./components/details3.vue";
import details4 from "./components/details4.vue";
import details5 from './components/details5.vue'
import details6 from './components/details6.vue'
import details7 from './components/details7.vue'
import details8 from './components/details8.vue'
import details9 from './components/details9.vue'
import details10 from './components/details10.vue'
import details11 from "./components/details11.vue";
import details12 from "./components/details12.vue";
import details13 from "./components/details13.vue";
import details14 from "./components/details14.vue";
export default {
  components: {
    heade,
    bottom,
    details1001,
    details1,
    details2,
    details1002,
    details1003,
    details3,
    details4,
    details5,
    details6,
    details7,
    details8,
    details9,
    details10,
    details11,
    details12,
    details13,
    details14,
  },
  data() {
    return {
      currentComponent: "details1001",
      // currentComponent:"details8",
      activeIndex: 0,
      activeTypeIndex: 1001,
      currentList: [],
      list: [
        {
          title: "人员位置管理",
          id: 1001,
          list: [
            {
              name: "外勤人员",
              id: 1,
            },
            {
              name: "室内人员",
              id: 2,
            },
          ],
        },
        {
          title: "车辆位置管理",
          id: 1002,
          list: [
            {
              name: "汽车金融风控",
              id: 3,
            },
            {
              name: "外勤作业车辆",
              id: 4,
            },
            {
              name: "货运物流车辆",
              id: 5,
            },
            {
              name: "应急保障车辆",
              id: 6,
            },
            {
              name: "网约租赁车辆",
              id: 7,
            },
            {
              name: "政企公车",
              id: 8,
            },
            {
              name: "工程车辆",
              id: 9,
            },
            {
              name: "特种车辆",
              id: 10,
            },
          ],
        },
        {
          title: "资产位置管理",
          id: 1003,
          list: [
            {
              name: "贵重物品",
              id: 11,
            },
            {
              name: "固定资产",
              id: 12,
            },
            {
              name: "包装设备",
              id: 13,
            },
            {
              name: "工程设备",
              id: 14,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    console.log(this.$route.query.id, ";;;;;;;;;;;;;;;;");
    let id = this.$route.query.id || "";
    if (id) {
      localStorage.setItem("solutionId", id)
      if (['1001', '1002', '1003'].includes(id)) {
        this.changeType(id);
      } else {
        this.changeInfo(id);
      }
    }
  },
  destroyed(){
      localStorage.setItem("solutionId", '1001')
  },
  methods: {
    changeTop(id) {
      if ([1001, 1002, 1003].includes(id)) {
        this.changeType(id);
      } else {
        this.changeInfo(id);
      }
    },
    changeType(id) {
      this.currentComponent = "details" + id;
      this.activeTypeIndex = id;
      localStorage.setItem("solutionId", id)
      this.activeIndex = "";
    },
    // changeFaType(id) {
    //   this.currentComponent = "details" + id;
    //   this.activeTypeIndex = id;
    //   this.activeIndex = "";
    // },
    changeInfo(id) {
      console.log(id, "id111111");
      this.currentComponent = "details" + id;
      this.activeTypeIndex = "";
      this.activeIndex = id;
      localStorage.setItem("solutionId", id)
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100vw;
  background-color: #fff;
  .main {
    width: 1200px;
    min-width: 1200px;
    min-height: calc(100vh - 606px);
    height: auto;
    margin: 0 auto;
    // background-color: pink;
    display: flex;
    justify-content: space-between;
  }
}
.menu {
  width: 200px;
  background-color: #fafafa;
  padding-top: 56px;
  padding-left: 12px;
  box-sizing: border-box;
  min-height: calc(100vh - 606px);
  height: auto;
  .line {
    width: 176px;
    height: 0px;
    border: 1px solid #dbdbdb;
    margin-bottom: 16px;
  }
  .line-l {
    width: 4px;
    height: 12px;
    background: #0f3391;
    border-radius: 3px 3px 3px 3px;
    margin-right: 8px;
  }
  .list {
    .list-l {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
    }
    .title {
      font-size: 16px;
      color: #2c2c2c;
      font-weight: 400;
      padding-left: 8px;
    }
    .list-item {
      display: flex;
      padding-left: 8px;
      .line {
        width: 4px;
        height: 12px;
        background: #0f3391;
        border-radius: 3px 3px 3px 3px;
        margin-right: 8px;
      }
      .name {
        font-weight: 300;
        font-size: 14px;
        color: #2c2c2c;
        line-height: 12px;
        padding-bottom: 16px;
        cursor: pointer;
      }
    }
  }
}
.details {
  width: 960px;
  margin-left: 40px;
}
</style>
