<!--
 * @Author: 小洋人
 * @Date: 2024-08-02 20:08:31
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-08-05 17:06:22
 * @FilePath: \kangmisi-officialwebsite\src\views\components\details3.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div class="pages">
    <div class="title">应用场景</div>
    <card :cardList="cardList"></card>
    <div class="line"></div>
    <div class="title">行业痛点</div>
    <wayPoint :list="pointList"></wayPoint>
    <div class="line"></div>
    <div class="title" style="margin-bottom: 24px">康米斯解决方案</div>
    <div class="text">
     以智能终端作为通讯入口采集车辆运行状态（位置、轨迹、告警等），依托康米斯大数据优势和技术能力得出可视化数据，帮助汽车金融公司打造标准化的贷后风控系统。通过智能监控管理和预警等手段，降低客户运营成本，保障客户资产安全 。
    </div>
    <img src="@/assets/images/solution/details3/img3.png" alt=""  style="width:960px;height:480px"/>
    <div class="line"></div>
    <div class="title">方案亮点</div>
    <light :list="lightList"></light>
    <div class="line"></div>
    <div class="title">客户案例</div>
    <swiper :list="swiperList"></swiper>
    <img src="@/assets/images/solution/details1/img2.png" alt="" class="img2" />
  </div>
</template>
<script>
import card from "./components/card-num.vue";
import wayPoint from "./components/wayPoint.vue";
import light from "./components/light.vue";
import swiper from "./components/swiper.vue";
export default {
  components: {
    wayPoint,
    card,
    light,
    swiper,
  },
  data() {
    return {
      swiperList: [
        {
          url: require("@/assets/images/solution/details3/s1.png"),
          title: "象屿",
          html: `为象屿提供<span style='color: #0F3391;font-weight: Bold;'>30w+</span>车载GPS定位器，结合人工智能汽车金融风控平台，有效保障了汽车融资租赁、车辆按揭贷款、以租代购等汽车金融资产安全，降低了潜在风险和损失。`,
        },
         {
          url: require("@/assets/images/solution/details3/s2.png"),
          title: "龙环",
          html: `为龙环提供<span  style='color: #0F3391;font-weight: Bold;'>100w+</span>车载GPS定位器，结合人工智能汽车金融风控平台，有效保障了汽车融资租赁、车辆按揭贷款、以租代购等汽车金融资产安全，降低了潜在风险和损失。`,
        }
      ],

      lightList: [
        {
          url: require("@/assets/images/solution/details3/light1.png"),
          title: "风险提醒",
          px: "104px",
          text: "常驻位置分析+多维度风险甄别(二押区域、长时间停留、异常出行等)，提高风险识别能力。",
        },
        {
          url: require("@/assets/images/solution/details3/light2.png"),
          title: "定位监控",
          px: "104px",
          text: "支持北斗/GPS/LBS/Wi-Fi等定位方式，实现对车辆远程监控管理，精准掌握车辆轨迹。",
        },
        {
          url: require("@/assets/images/solution/details3/light3.png"),
          title: "实时追车",
          px: "104px",
          text: "车辆逃逸后，追车团队可第一时间通过APP上的车辆位置动态追车，快速追回违约车辆。",
        },
        {
          url: require("@/assets/images/solution/details3/light4.png"),
          title: "防探测，防搜索",
          px: "104px",
          text: "防伪基站探测，降低被拆卸风险；光感防拆，设备被拆卸实时报警不错过追车时机。",
        },
      ],
      pointList: [
        {
          url: require("@/assets/images/solution/details3/point1.png"),
          title: "缺乏信息化监控管理",
          text: "风控公司对客户和车辆通过传统手段管理，容易出现因盲点过多无法准确得知客户和车辆动态的问题，对追缴造成极大不便",
        },
        {
          url: require("@/assets/images/solution/details3/point2.png"),
          title: "贷后车辆行踪不明",
          text: "用车实况无法及时掌控，用车效率无法估算",
        },
        {
          url: require("@/assets/images/solution/details3/point3.png"),
          title: "欺诈造成风险集中",
          text: "虚假报帐，油费、维修费居高不下，造车企业经营成本上升",
        },
        {
          url: require("@/assets/images/solution/details3/point4.png"),
          title: "风控环节守旧难追讨",
          text: "疲劳驾驶、超速、急加速等危险驾驶行为，车辆维修保养不及时等，易造成不良后果",
        },
      ],
      cardList: [
        {
          width: "222px",
        height:'160px',
          url: require("@/assets/images/solution/details3/card1.png"),
          text: "融资租赁",
        },
        {
          width: "222px",
        height:'160px',

          url: require("@/assets/images/solution/details3/card2.png"),
          text: "抵押贷款",
        },
        {
          width: "222px",
        height:'160px',

          url: require("@/assets/images/solution/details3/card3.png"),
          text: "担保",
        },
        {
          width: "222px",
        height:'160px',

          url: require("@/assets/images/solution/details3/card4.png"),
          text: "二手车交易",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2c2c2c;
  letter-spacing: 1px;
  margin: 40px 0;
}
.line {
  margin-top: 40px;
  width: 960px;
  height: 0px;
  border: 1px solid #dbdbdb;
}
.text {
  width: 960px;
  height: 60px;
  font-weight: 300;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 20px;
  margin-bottom: 24px;
}
.img2 {
  margin: 112px 0;
  width: 960px;
  height: 202px;
}
</style>