<!--
 * @Author: 小洋人
 * @Date: 2024-08-05 09:22:39
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-08-05 10:32:23
 * @FilePath: \kangmisi-officialwebsite\src\views\components\components\card-num.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div>
    <div class="list">
      <div v-for="(item, index) in cardList" :key="index" class="item" :style="{width:item.width,height:item.height}">
        <img :src="item.url" alt=""   :style="{width:item.width,height:item.height}"/>
        <div class="text" :style="{width:item.width}">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cardList: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.item {
  // width: 222px;
  height: 160px;
  margin-bottom: 20px;
  position: relative;
}
img{
  height: 207px;
}
.text {
  position: absolute;
  left: 0;
  bottom: 0;
  // width: 222px;
  height: 37px;
  background: #2c2c2c;
  border-radius: 0px 0px 8px 8px;
  opacity: 0.8;
  text-align: center;
  line-height: 37px;
  color: #fff;
}
</style>