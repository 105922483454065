<!--
 * @Author: 小洋人
 * @Date: 2024-07-31 17:55:39
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-08-05 11:24:18
 * @FilePath: \kangmisi-officialwebsite\src\views\components\components\swiper.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div class="carousel">
    <!-- <button @click="prevSlide">Previous</button> -->
    <div class="change" @click="prevSlide" style="margin-right: 24px">
      <img src="@/assets/images/solution/details1/left.png" alt="" />
    </div>

    <div class="xxx">
      <div
        class="carousel-inner"
        :style="{ transform: 'translateX(' + currentSlideIndex * -100 + '%)' }"
      >
        <div
          v-for="(slide, index) in list"
          :key="index"
          class="carousel-item"
          :class="{ active: index === currentSlideIndex }"
        >
          <img :src="slide.url" alt="Slide Image" />
          <div class="content">
            <div class="title">{{ slide.title }}</div>
            <div class="html" v-html="slide.html"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="change" @click="nextSlide" style="margin-left: 24px">
      <img src="@/assets/images/solution/details1/right.png" alt="" />
    </div>
    <!-- <button @click="nextSlide">Next</button> -->
  </div>
</template>
 
<script>
export default {
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {
      currentSlideIndex: 0,
    };
  },
  methods: {
    nextSlide() {
      this.currentSlideIndex = (this.currentSlideIndex + 1) % this.list.length;
    },
    prevSlide() {
      this.currentSlideIndex =
        (this.currentSlideIndex - 1 + this.list.length) % this.list.length;
    },
  },
};
</script>
 
<style scoped lang="less">
.carousel {
  display: flex;
  align-items: center;
}
.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;

  width: 832px;
}
.xxx {
  width: 832px;
  overflow-x: hidden;
  box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.16);

}
.carousel-item {
  width: 832px;
  height: 272px;
  flex-shrink: 0;
  display: flex;

}
// .carousel-item.active {
//   display: block;
// }
img {
  width: 432px;
  height: 272px;
}
.title {
  margin: 88px 0px 16px 32px;
  font-weight: bold;
  font-size: 16px;
  color: #2c2c2c;
}
.html {
  font-weight: 300;
  font-size: 14px;
  color: #2c2c2c;
  margin-left:32px;
  margin-right:24px;
   span {
    font-weight: 700;
    color: #0f3391;
  }
 
}

.change {
  width: 40px;
  height: 48px;
  cursor: pointer;
  img {
    width: 40px;
    height: 48px;
  }
}
.content{
  background: #FFFFFF;

}
</style>